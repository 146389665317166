import axios from "axios";
import { statesMap } from "../utils/uf";


export async function getCepData(cep) { 
  cep = cep.replace(/\D+/g, '')
  if(cep.length !== 8) {
    return false;
  }
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`).then((res) => 
    res.data.erro ? false : res.data
  ).catch(() => false);

  
  if(!response) {
    return false;
  }
  
  const { localidade, uf} = response;
  const name = await getNameStateByUf(uf);
  return {
    localidade,
    name,
    uf
  }
}


export async function getNameStateByUf(uf) {
  return statesMap[uf];
}


export async function getStates() {
  const result = await axios.get(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
    );
    return result.data;
  }
  
  export async function getCitiesFrom(uf) {
    const result = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios
      `
      );
  return result.data;
}
