import { useFormik } from "formik";
import { useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import {
  SignupClientStep01,
  SignupClientStep02,
  SignupClientStep03,
} from "../../utils/validation_schemas";
import InputMask from "react-input-mask";
import { createSignupClientFormData } from "../../utils/payloads";
import { ProgressBar } from "react-bootstrap";
import { availableCpf, availableCpfCnpj, availableEmail } from "../../services/api";
import useNotification from "../../contexts/notification";
import Adresss from "../Adresss";

export default function SignupClientWizard({ onSubmit }) {
  const { setMessage } = useNotification();
  const [currentTab, setCurrentTab] = useState(0);
  const [client, setClient] = useState({
    type: "client",
    name: "",
    surname: "",
    birthDate: "",
    cpf: "",
    telephone: "",
    email: "",
    complement: "",
    number: "",
    neighborhood: "",
    zip_code: "",
    street: "",
    city: "",
    state: "",
  });

 
  function next() {
    if (currentTab < 2) {
      setCurrentTab((tab) => tab + 1);
    }
  }

  function prev() {
    if (currentTab > 0) {
      setCurrentTab((tab) => tab - 1);
    }
  }

  const formik = useFormik({
    initialValues: {
      complement: client.complement,
      number: client.number,
      neighborhood: client.neighborhood,
      zip_code: client.zip_code,
      street: client.street,
      city: client.city,
      state: client.state,
    },
    validationSchema: SignupClientStep03,
    onSubmit: (values) => {
      setClient((cl) => ({ ...cl, ...values }));
      const data = { ...client, ...values, state: values.state, city: values.city };

      onSubmit(createSignupClientFormData(data));
    },
  });

  const Step01 = () => {
    const formik = useFormik({
      initialValues: {
        name: client.name,
        surname: client.surname,
        birthDate: client.birthDate,
        cpf: client.cpf,
        acceptTerms: false
      },
      validationSchema: SignupClientStep01,
      onSubmit: async (values) => {
        setClient((cl) => ({ ...cl, ...values }));
        const cpf = values.cpf.replace(/\D/g, "");
        await Promise.all([availableCpf(cpf), availableCpfCnpj(cpf)])
          .then(
            (res) => {
              if(res[0].status && res[1].status) {
                next();
              }
            }, (err) => {
              if(!err.response.data.status && err.response.data.status !== undefined) {
                setMessage({
                  error: true,
                  content: "O CPF informado já está em uso.",
                });
              } else {
                setMessage({
                  error: true,
                  content: "O CPF informado não é valido."
                })
              }
            }
          )
        // await availableCpf(values.cpf.replace(/\D/g, "")).then(
        //   (res) => {
        //     if (res.status) {
        //       next();
        //     }
        //   },
        //   (err) => {
        //     if(!err.response.data.status && err.response.data.status !== undefined) {
        //       setMessage({
        //         error: true,
        //         content: "O CPF informado já está em uso.",
        //      required });
        //     } else {
        //       setMessage({
        //         error: true,
        //         content: "O CPF informado não é valido."
        //       })
        //     }
            
        //   }
        // );
      },
    });

    return (
      <Form noValidate onSubmit={formik.handleSubmit} className="my-5">
        <h6 className="mb-3 text-primary">
          <span className="text-secondary bg-primary p-1 rounded mr-1">1</span>
          Dados pessoais
        </h6>
        <Form.Group>
          <Row>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>Nome *</Form.Label>
              <Form.Control
                className="my-1"
                type="text"
                name="name"
                placeholder="Seu nome completo"
                required="Campo necessário"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={
                  formik.errors.name && formik.touched.name
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>Sobrenome *</Form.Label>
              <Form.Control
                className="my-1"
                type="text"
                name="surname"
                placeholder="Seu sobrenome"
                required="Campo necessário"
                onChange={formik.handleChange}
                value={formik.values.surname}
                isValid={formik.touched.surname && !formik.errors.surname}
                isInvalid={
                  formik.errors.surname && formik.touched.surname
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.surname}
              </Form.Control.Feedback>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>Data de nascimento*</Form.Label>
              <Form.Control
                as={InputMask}
                mask="99/99/9999"
                className="my-1"
                type="text"
                name="birthDate"
                placeholder="DD/MM/AAAA"
                onChange={formik.handleChange}
                value={formik.values.birthDate}
                isValid={formik.touched.birthDate && !formik.errors.birthDate}
                isInvalid={formik.errors.birthDate && formik.touched.birthDate}
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.birthDate}
              </Form.Control.Feedback>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>CPF *</Form.Label>
              <Form.Control
                as={InputMask}
                mask="999.999.999-99"
                className="my-1"
                type="text"
                name="cpf"
                placeholder="Seu CPF"
                onChange={formik.handleChange}
                value={formik.values.cpf}
                isValid={formik.touched.cpf && !formik.errors.cpf}
                isInvalid={
                  formik.errors.cpf && formik.touched.cpf ? true : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.cpf}
              </Form.Control.Feedback>
            </Col>
            <Col xs="auto" className="mt-2">
              <Form.Check
                custom
                id="custom-checkbox-terms-user"
                name="acceptTerms"
                type="checkbox"
                label="Aceito os Termos e Condições*"
                checked={formik.values.acceptTerms}
                onChange={formik.handleChange}
                isValid={formik.touched.acceptTerms && !formik.errors.acceptTerms}
                isInvalid={
                  formik.errors.acceptTerms &&
                  formik.touched.acceptTerms
                    ? true
                    : false
                }
              />
            </Col>
          </Row>
        </Form.Group>
        <Button variant="primary" type="submit">
          Próximo
        </Button>
        <ProgressBar now={33} label={`Etapa 1 de 3`} className="mt-3" />
      </Form>
    );
  };

  const Step02 = () => {
    const formik = useFormik({
      initialValues: {
        telephone: client.telephone,
        email: client.email,
      },
      validationSchema: SignupClientStep02,
      onSubmit: async (values) => {
        setClient((cl) => ({ ...cl, ...values }));
        const result = await availableEmail(values.email).catch((err) => err.response.data)
        if(result.status) {
          next();
        } else {
          setMessage({
            error: true,
            content: 'Já existe usuário utilizando esse e-mail',
          });
        }
      },
    });

    return (
      <Form noValidate onSubmit={formik.handleSubmit} className="my-5">
        <h6 className="mb-3 text-primary">
          <span className="text-secondary bg-primary p-1 rounded mr-1">2</span>
          Contato
        </h6>
        <Form.Group>
          <Row>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>Telefone *</Form.Label>
              <Form.Control
                as={InputMask}
                mask="(99)99999-9999"
                className="my-1"
                type="text"
                name="telephone"
                placeholder="Telefone"
                onChange={formik.handleChange}
                value={formik.values.telephone}
                isValid={formik.touched.telephone && !formik.errors.telephone}
                isInvalid={
                  formik.errors.telephone && formik.touched.telephone
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.telephone}
              </Form.Control.Feedback>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>E-mail *</Form.Label>
              <Form.Control
                className="my-1"
                type="email"
                name="email"
                placeholder="Seu email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={
                  formik.errors.email && formik.touched.email ? true : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Button
          variant="outline-primary"
          onClick={() => {
            setClient((ent) => ({
              ...ent,
              ...formik.values,
            }));

            prev();
          }}
        >
          Anterior
        </Button>
        <Button variant="primary" type="submit" className="mx-2">
          Próximo
        </Button>
        <ProgressBar now={66} label={`Etapa 2 de 3`} className="mt-3" />
      </Form>
    );
  };

  return (
    <Tabs defaultActiveKey={0} activeKey={currentTab} className="border-0">
      <Tab eventKey={0}>
        <Step01 />
      </Tab>
      <Tab eventKey={1}>
        <Step02 />
      </Tab>
      <Tab eventKey={2}>
        <Form noValidate onSubmit={formik.handleSubmit} className="my-5">
          <Adresss formik={formik} tab={3}/>
          <Button
            variant="outline-primary"
            onClick={() => {
              setClient((ent) => ({
                ...ent,
                ...formik.values,
                state: formik.values.state,
                city: formik.values.city
              }));

              prev();
            }}
          >
            Anterior
          </Button>
          <Button variant="primary" type="submit" className="mx-2">
            Criar conta
          </Button>
          <ProgressBar now={100} label={`Etapa 3 de 3`} className="mt-3" />
        </Form>
      </Tab>
    </Tabs>
  );
}
