import useAuth from "../../../contexts/auth";
import UpdateAdminForm from "../../../components/UpdateAdminForm";
import { useState } from "react";
import { getStates } from "../../../services/address";
import { useEffect } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { Alert, Button } from "react-bootstrap";
import { deleteAdmin } from "../../../services/api";
import ConfirmDeleteAccountModal from "../ConfirmDeleteAccountModal";
import useNotification from "../../../contexts/notification";

export default function AccountAdmin() {
  const { user, logout } = useAuth();
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { setMessage } = useNotification();

  useEffect(() => {
    getStates().then((data) => {
      setStates(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <UpdateAdminForm
        adminId={user.id}
        states={states}
        onLoaded={() => {
          setShowDeleteBtn(true);
        }}
      />
      {showDeleteBtn && (
        <Alert variant="danger" className="mt-4">
          <Alert.Heading style={{ fontSize: "1.2em", fontWeight: "bold" }}>Você tem certeza?</Alert.Heading>
          <p>Esta ação resultará na exclusão da sua conta na plataforma Caçadores de ofertas, você não poderá mais recuperar seus dados.</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(true)}
            >
              Excluir conta
            </Button>
          </div>
        </Alert>
      )}

      <ConfirmDeleteAccountModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={async () => {
          await deleteAdmin(user);
          setMessage({
            error: true,
            content: "Administrador deletado com sucesso",
          })
          logout(true);
        }}
      />
    </>
  );
}
