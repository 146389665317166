import { createContext, useContext } from "react";

export const InfoModalContext = createContext({});

export default function useInfoModal() {
    const context = useContext(InfoModalContext);
    if (!context) {
        throw new Error("useLocation must be used within an InfoModalProvider.");
    }

    return context;
}