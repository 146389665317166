import { Redirect } from "react-router-dom";
import useAuth from "../../contexts/auth";

export default function Entrepreneur() {
  const { user } = useAuth();
  if (user && user.type !== "poster") {
    
    return <Redirect to="/" />;
  } else {
    return <Redirect to="/poster/produtos" />;
  }
}
