import { Line } from "react-chartjs-2";
import {
  getDashboardEntrepreneur,

} from "../../services/api";
import { useEffect, useState } from "react";
import useNotification from "../../contexts/notification";
import LoadingIndicator from "../LoadingIndicator";

export default function GraficoEmpreendedor() {
  const [dashboard, setDashboard] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setMessage } = useNotification();
  
  useEffect(() => {
    getDashboardEntrepreneur().then(
      (data) => {
        setDashboard(data.generalStoreCreate);
        setLoading(false);
      }, (err) => setMessage({
        error: true,
        content: "Ocorreu um erro, por favor tente novamente."
      })
    );

  }, [setMessage]);
    return (
        <>
          {loading && <LoadingIndicator />}
          {!loading &&
            <Line
                data={{
                    labels: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro"
                    ],
                    datasets: [
                      {
                        label: "R$ Vendas",
                        data: dashboard,
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                          "rgba(75, 192, 192, 0.2)",
                          "rgba(153, 102, 255, 0.2)",
                          "rgba(255, 159, 64, 0.2)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                          "rgba(75, 192, 192, 1)",
                          "rgba(153, 102, 255, 1)",
                          "rgba(255, 159, 64, 1)",
                        ],
                        borderWidth: 5,
                      },
                     
                    ],
                  }}
                  height={400}
                  options={{
                    maintainAspectRatio: false,
                    scale: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                }}
            />
              }
        </>
    )
}