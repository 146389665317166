import { Link } from "react-router-dom";
import "./styles.scss";
import { HiChartSquareBar } from "react-icons/hi";
import {
  FaStore,
  FaShoppingBag,
  FaLightbulb,
  FaGratipay,
  FaMapMarked,
  FaListAlt,
  FaMapMarkedAlt
} from "react-icons/fa";
import { FiGrid } from "react-icons/fi";

export default function SidebarPanelNavigation({ isAdmin }) {
  const renderNavigation = () => {
    return (
      <nav className="text-secondary">
        {isAdmin && (
          <ul>
            <li>
              <HiChartSquareBar size={20} />
              <Link className="ml-2 text-secondary" to="/admin/dashboard">
                Dashboard
              </Link>
            </li>
            <li className="my-2">
              <FaStore size={20} />
              <Link className="ml-2 text-secondary" to="/admin/usuarios">
                Usuários
              </Link>
            </li>

            <li className="my-2">
              <FaMapMarked size={20} />
              <Link className="ml-2 text-secondary" to="/admin/localidades">
                Localidades
              </Link>
            </li>
            <li>
              <FiGrid size={20} />
              <Link className="ml-2 text-secondary" to="/admin/categorias">
                Categorias
              </Link>
            </li>
            <br />
            <li>
              <FiGrid size={20} />
              <Link className="ml-2 text-secondary" to="/admin/parceiros">
                Parceiros
              </Link>
            </li>
            <br />
            <li>
              <FaListAlt size={20} />
              <Link className="ml-2 text-secondary" to="/admin/tipos-categorias">
                Tipos de Categorias
              </Link>
            </li>
            <br />
            <li>
              <FaShoppingBag size={20} />
              <Link className="ml-2 text-secondary" to="/admin/products">
                Todos os Produtos
              </Link>
            </li>

            <br />
            <li>
              <FaListAlt size={20} />
              <Link className="ml-2 text-secondary" to="/admin/banner">
                Todos os Banners
              </Link>
            </li>
            <br />
            <li>
              <FaListAlt size={20} />
              <Link className="ml-2 text-secondary" to="/admin/ribbon-badges">
                Todos os Emblemas
              </Link>
            </li>
          </ul>
        )}
        {!isAdmin && (
          <ul>


            <li className="my-2">
              <FaStore size={20} />
              <Link className="ml-2 text-secondary" to="/poster/produtos">
                Meus Produtos
              </Link>
            </li>


          </ul>
        )}
      </nav>
    );
  };

  return (
    <div className="sidebar-panel-nav d-none d-sm-nome d-md-none d-lg-block  text-secondary" style={{ background: "#000" }}>
      {isAdmin && <h5 className="mb-4">Painel de Controle</h5>}
      {!isAdmin && <h5 className="mb-4">Painel do Postador</h5>}
      {renderNavigation()}
    </div>
  );
}
