import { useEffect } from "react";
import { useState } from "react";
import { AuthContext } from "../contexts/auth";
import {
  clearBearerToken,
  signupUser,
  setBearerToken,
  loginUser,
  getGeneralRole,
  logoutUser,
  loginGoogle
} from "../services/api";
import { createLoginFormData } from "../utils/payloads";

import Splash from "../containers/Splash";
import useNotification from "../contexts/notification";
import useStorage from "../hooks/useLocalStorage";

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setMessage } = useNotification();
  const [token, setToken] = useStorage("@app_mercadim:token", null);

  useEffect(() => {
    if (token) {
      setBearerToken(token.token);
      getGeneralRole().then(
        (data) => {
          setUser({
            type: data.role,
            id: data.id,
            name: data.name
          });

          setLoading(false);
        },
        (_) => {
          setToken(null);
          setLoading(false);
          setMessage({
            content: "Sua sessão expirou, realize o login novamente.",
          });
        }
      );
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage, token]);

  const signup = async (user, type) => {
    try {
      const data = await signupUser(user, type);

      if (data.access_token) {

        setToken({ token: data.access_token });
        setBearerToken(data.access_token);
        setUser({
          id: data.id,
          type: data.type,
          name: data.name
        });

        return { signed: true };
      }

      return { signed: false, errors: data.errors };
    } catch (err) {
      return { signed: false, errors: err.response.data.errors };
    }
  };

  const login = async (loginState,isGoogleLogin) => {
    
    console.log('loginState', loginState);

    try {
      if (isGoogleLogin) {
        // Enviar os detalhes do usuário Google para a rota de login do Google no back-end
       const x = loginGoogle(loginState)
       x.then((response) =>{
        if (response.access_token) {
          setToken({ token: response.access_token });
          setBearerToken(response.access_token);
          setUser({
            id: response.id,
            type: response.type,
            name: response.name
          });
          return { signed: true };
        }else{
          return { signed: false, errors: x.errors };
        }
       
       });
       
       
      } else {
        // Procedimento de login normal
        const user = createLoginFormData(loginState);
        const data = await loginUser(user, loginState.type);
        if (data.access_token) {
          setToken({ token: data.access_token });
          setBearerToken(data.access_token);
          setUser({
            id: data.id,
            type: data.type,
            name: data.name
          });
          return { signed: true };
        }
        return { signed: false, errors: data.errors };
      }

      

      
    } catch (err) {
      return { signed: false, errors: err.response.data.errors };
    }
  };

  const logout = async (fromDelete = false) => {
    if (!fromDelete) {
      await logoutUser(user.type);
      setMessage({ message: "Sessão de usuário encerrada." });
    }

    localStorage.removeItem('@app_mercadim:token');
    clearBearerToken();
    setUser(null);
    window.location.reload()
  };

  useEffect(() => {
    console.log('Init AuthProvider')
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signup,
        login,
        logout,
      }}
    >
      {loading ? <Splash /> : <div>{children}</div>}
    </AuthContext.Provider>
  );
}
