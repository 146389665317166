import RedirectContainer from "../../../components/RedirectContainer";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { useEffect, useState } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import useNotification from "../../../contexts/notification";
import {
  myOrders,
  confirmOrderPayment,
  pdfOrderPayment,
  CancelPayment
} from "../../../services/api";
import { FaShoppingBag } from "react-icons/fa";
import { currency } from "../../../utils/mappers";
import { MdOutlinePayments, MdOutlinePictureAsPdf } from "react-icons/md";

export default function Orders() {
  const [orders, setOrders] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { setMessage } = useNotification();
  const refresh = () => {
    // setIsLoading(true);
    myOrders().then(
      (data) => {
        setOrders(data.reverse());
      },
      (err) =>
        setMessage({
          error: true,
          content: "Ocorreu um erro, tente novamente depois.",
        })
    );
    // setIsLoading(false);
  };

  useEffect(() => {
    myOrders().then(
      (data) => {
        setOrders(data.reverse());
        setIsLoading(false);
      },
      (err) =>
        setMessage({
          error: true,
          content: "Ocorreu um erro, tente novamente depois.",
        })
    );
  }, [setMessage]);

  async function confirmOrder(payload) {
    setIsLoading(true);
    await confirmOrderPayment(payload).then(()=>{
      refresh();
      setIsLoading(false);
    });
    
    await showPDF(payload.id);
  }

  async function showPDF(id) {
    const pdf = await pdfOrderPayment(id);
    const file = new Blob([pdf], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  }
  async function CancelOrder(payload) {
    setIsLoading(true)
    await CancelPayment(payload).then(()=>{
      refresh();
      setIsLoading(false);
    });
   
  }

  return (
    <>
      <RedirectContainer role="poster">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin={false} />
            <div className="content-panel">
              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <>
                  <div className="py-3">
                    <h5 className="text-primary mb-3 mb-sm-0">
                      <FaShoppingBag className="mr-2" />
                      Pedidos
                    </h5>
                  </div>

                  <Row>
                    <Col xs={12}>
                      <Table striped bordered responsive size="sma">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Status</th>
                            <th>Cliente</th>
                            <th>Total</th>
                            <th>Data</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order) => (
                            <tr key={order.id}>
                              <td>{order.id}</td>
                              <td>{order.status}</td>
                              <td>{order.user_name}</td>
                              <td>{currency(order.total)}</td>
                              <td>
                                {new Date(order.created_at).toLocaleDateString(
                                  "pt-BR"
                                )}
                              </td>
                              <td style={{ width: "250px" }}>
                                <Row
                                  noGutters
                                  className="justify-content-between"
                                >
                                  { order.status === "Em aberto" && (
                                    <>
                                      <Col xs="auto">
                                        <Button
                                          variant="primary"
                                          onClick={() => {
                                            confirmOrder({ id: order.id });
                                            refresh();
                                          }}
                                        >
                                          <MdOutlinePayments size="18" /> <small>Confirmar</small>
                                        </Button>
                                      </Col>
                                      <Col xs="auto">
                                        <Button
                                          variant="danger"
                                          onClick={() => {
                                            CancelOrder({ id: order.id });
                                            refresh();
                                          }}
                                        
                                        >
                                          <MdOutlinePayments size="18" /> <small>Cancelar</small>
                                        </Button>
                                      </Col>
                                    </>
                                  )}
                                      
                                  { order.status === "Confirmado" && (
                                    <>
                                      <Col xs="auto">
                                        <Button
                                          variant="info"
                                          onClick={() => {
                                            confirmOrder({ id: order.id });
                                          }}
                                        >
                                          <MdOutlinePictureAsPdf size="18" /> <small>Comprovante</small>
                                        </Button>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </RedirectContainer>
    </>
  );
}
