import RedirectContainer from "../../../components/RedirectContainer";
import { Container, Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../../assets/img/logo-vertical-colored.svg";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { getOrder, confirmOrderPayment, pdfOrderPayment, CancelPayment } from "../../../services/api";
import { useParams, useHistory } from "react-router-dom";
import useNotification from "../../../contexts/notification";
import useAuth from "../../../contexts/auth";
import { Form as FormikForm, Field, Formik } from "formik";
import { LoginSchema } from "../../../utils/validation_schemas.js";
import ErrorAlert from "./../../../components/ErrorAlert";
import Footer from "./../../../components/Footer";
import { cpfCnpj } from "../../../utils/formatters";
const ConfirmPayment = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const history = useHistory();
  const { setMessage } = useNotification();
  const { user, login } = useAuth();
  const [status, setStatus] = useState({ status: "start" });
  useEffect(() => {
    if (user) {
      getOrder(id).then(
        (data) => {
          setOrder(data.Pedido);
          setIsConfirmed((data.Pedido.status === "Confirmado") ? true : false);
          setIsLoading(false);
        }, (err) => {

          if (err.response.data.msg === "Pedido não encontrado ou você não tem permissão") {
            history.push("/");
            setMessage({
              error: true,
              content: err.response.data.msg,
            })
          }
        });
    }
  }, [id, history, setMessage, user, isConfirmed]);
  async function showPDF(id) {
    const pdf = await pdfOrderPayment(id);
    const file = new Blob(
      [pdf],
      { type: 'application/pdf' }
    );

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  }
  const renderLoading = () => {
    if (status.status !== "loading") {

      return null;
    }

    return (
      <div className="d-flex justify-content-center">
        <Spinner variant="primary" animation="border" size="sm" />
      </div>
    );

  };

  async function confirmOrder(payload) {
    setIsLoading(true)
    await confirmOrderPayment(payload).then(() => {
      setIsConfirmed(true);
      setIsLoading(false);

    });
    await showPDF(payload.id);

  }
  async function CancelOrder(payload) {
    setIsLoading(true)
    await CancelPayment(payload).then(() => {
      setIsConfirmed(true);
      setIsLoading(false);
    });

  }
  const checkUserRole = () => {
    if (!user) {
      // Se o usuário não estiver autenticado, retorne false

      return false;
    } else if (user.type !== 'poster') {
      history.push('/');

    }
    return user.type === 'poster';

    // Se o usuário estiver autenticado e o seu papel for "entrepreneur", retorne true

  }
  const clearStatus = () => setStatus({ status: "start" });
  return (
    <>
      {checkUserRole() ? (
        <RedirectContainer role="poster">
          <Helmet title="Jersu Indica: Confirmação de Pagamento" />
          <Container fluid>
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Row className="mt-5 justify-content-center">
                <Col xs={12} sm={8} md={6} xl={4}>
                  <Card className="text-center">
                    <Card.Img className="mt-5 align-self-center" variant="top" src={logo} style={{ width: "50%" }} />
                    <Card.Body className="px-5">
                      <Card.Title>
                        <h4 className="text-primary text-center">Confirmação de Pagamento</h4>
                      </Card.Title>
                      <Card.Text className="text-justify">Loja: {order.name_establishment}</Card.Text>
                      <Card.Text className="text-justify">Cliente: {order.user_name}</Card.Text>
                      <Card.Text c
                        lassName="text-justify">Total: R$ {order.total}</Card.Text>

                      {isConfirmed ? (
                        <Row className="mt-2">
                          <Col>
                            {order.status !== "Cancelado" ? (
                              <Button variant="info" onClick={() => { showPDF(order.id) }}> PDF </Button>
                            ) : (
                              <div></div>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        order.status !== "Cancelado" ? (
                          <Row className="mt-2">
                            <Col>
                              <Button variant="danger" onClick={() => { CancelOrder(order) }}>Cancelar</Button>
                            </Col>
                            <Col>
                              <Button variant="primary" onClick={() => { confirmOrder(order) }}>Confirmar</Button>
                            </Col>
                          </Row>
                        ) : (
                          <div></div>
                        )
                      )
                      }
                    </Card.Body>
                    <Card.Footer>
                      Pedido: # {order.id} - Status: {order.status}
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </RedirectContainer>
      ) : (
        <div>

          <Container fluid="md" className="py-4">
            <Formik
              enableReinitialize
              validationSchema={LoginSchema}
              initialValues={{
                cpf_cnpj: "",
                password: "",

                show_password: false,
              }}
              onSubmit={async (values) => {
                setStatus({ status: "loading" });

                const status = await login(values);
                setStatus(
                  status.signed
                    ? { status: "success" }
                    : { status: "error", errors: status.errors }
                );
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form noValidate as={FormikForm}>
                  <Row className="justify-content-center">
                    <Col sm={8} lg={6} className="align-self-center">
                      <h3 className="text-center text-primary pt-4 mb-2">Credenciais para Acessar o pedido</h3>

                      {renderLoading()}
                      {status.status === "error" && (
                        <ErrorAlert
                          msg={Object.values(status.errors)[0]}
                          onClose={clearStatus}
                        />
                      )}

                      <Form.Group className="mb-4" controlId="formCpfCnpj">
                        <Form.Label>CPF/CNPJ *</Form.Label>
                        <Form.Control
                          as={Field}
                          title="Digite o CPF ou CNPJ"
                          type="text"
                          name="cpf_cnpj"
                          size="lg"
                          pattern="\d+"
                          placeholder="CPF ou CNPJ sem pontuação"
                          required
                          value={values.cpf_cnpj}
                          onChange={(e) =>
                            setFieldValue("cpf_cnpj", cpfCnpj(e.target.value))
                          }
                          isValid={touched.cpf_cnpj && !errors.cpf_cnpj}
                          isInvalid={
                            errors.cpf_cnpj && touched.cpf_cnpj ? true : false
                          }
                          autoComplete="on"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cpf_cnpj}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="formSenha">
                        <Form.Label>Senha *</Form.Label>
                        <Form.Control
                          as={Field}
                          label="Senha*"
                          required="Campo necessário"
                          size="lg"
                          name="password"
                          type={values.show_password ? "text" : "password"}
                          isValid={touched.password && !errors.password}
                          isInvalid={
                            errors.password && touched.password ? true : false
                          }
                          placeholder="Sua senha"
                          autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formShowPassword" as={Row}>
                        <Col xs="auto">
                          <Form.Check
                            as={Field}
                            id="show-password-1"
                            name="show_password"
                            type="switch"
                            label="Mostrar senha"
                            checked={values.show_password}
                            custom
                          />
                        </Col>
                      </Form.Group>
                      <Button
                        className="my-1"
                        variant="primary"
                        type="submit"
                        block
                      >
                        Acessar
                      </Button>

                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
          <Footer />
        </div>
      )
      }
    </>
  )
}

export default ConfirmPayment;