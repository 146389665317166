import { cpfMask, formatDate, telephoneMask } from "./formatters";
import * as yup from "yup";
import moment from "moment";
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

function digitsOnly(value) {
  return /^\d+$/.test(value);
}

function cpfOnly(value) {
  return cpfMask.test(value);
}

function telephoneOnly(value) {
  return telephoneMask.test(value);
}

export const LoginSchema = yup.object().shape({
  email: yup.string().required("Email obrigatório").email("Email inválido"),
  password: yup.string().required("Senha necessária"),
});

export const SignupClientSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[^0-9]+$/, "Nome não pode conter números")
    .required("Nome obrigatório"),

  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  email: yup.string().required("Email obrigatório").email("Email inválido"),

});

export const SignupClientStep01 = yup.object().shape({
  name: yup
    .string()
    .matches(/^[^0-9]+$/, "Nome não pode conter números")
    .required("Nome obrigatório"),
  surname: yup.string().required("Sobrenome obrigatório"),
  birthDate: yup
    .string()
    .test("IncompleteDate", "Data incompleta", (value) => {
      return !value?.includes("_");
    })
    .test("ValidDate", "Data Invalida", (value) => {
      return moment(value, "DD-MM-YYYY").isValid();
    })
    .required("Data de nascimento obrigatória"),
  cpf: yup
    .string()
    .length(14, "CPF incompleto")
    .required("CPF obrigatório")
    .test("only-cpf", "O valor deve ser um CPF", cpfOnly),
  acceptTerms: yup
    .bool()
    .oneOf([true], "É necessário aceitar os Termos e Condições"),
});

export const SignupClientStep02 = yup.object().shape({
  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  email: yup.string().email("Email inválido").required("E-mail necessário"),
});

export const SignupClientStep03 = yup.object().shape({
  number: yup.string().required("Número obrigatório"),
  neighborhood: yup.string().required("Bairro obrigatório"),
  zip_code: yup
    .string()
    .required("CEP obrigatório")
    .test("cep-valid", "CEP incompleto", (value) => {
      return !value?.includes("_");
    })
    .required("CEP obrigatório"),
  city: yup.string().required("Cidade obrigatória"),
  state: yup.string().required("Estado obrigatório"),
  street: yup.string().required("Rua obrigatória"),
  complement: yup.string().required("Complemento obrigatório"),
});

const cpfCnpj = (value = '') => {
  const cleanedValue = value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
  if (cleanedValue.length <= 11) {
    return cpfValidator.format(cleanedValue); // Formata CPF
  } else {
    return cnpjValidator.format(cleanedValue); // Formata CNPJ
  }
};
export const SignupEntrepreneurStep01 = yup.object().shape({
  name_establishment: yup.string().required("Nome obrigatório"),
  cpf_cnpj: yup
  .string()
  .transform(cpfCnpj) // Aplica a função de formatação
  .test('cpf-cnpj-validation', 'CPF/CNPJ inválido', (value = '') => {
    const cleanedValue = value.replace(/[^\d]/g, '');
    if (cleanedValue.length === 11) {
      return cpfValidator.isValid(cleanedValue); // Verifica se o CPF é válido
    } else if (cleanedValue.length === 14) {
      return cnpjValidator.isValid(cleanedValue); // Verifica se o CNPJ é válido
    }
    return false; // Caso não seja nem CPF nem CNPJ válido
  })
  .required("CPF ou CNPJ obrigatório"),
  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  acceptTerms: yup
    .bool()
    .oneOf([true], "É necessário aceitar os Termos e Condições"),
});

export const SignupEntrepreneurStep02 = yup.object().shape({
  delivery_method: yup.string().required("Método de entrega obrigatório"),
  email: yup.string().email("Email inválido").required("E-mail necessário"),
  logo_store: yup
    .mixed()
    .test(
      "is-big-file",
      "Imagem muito grande, maior que 2MB",
      checkIfFilesAreTooBig
    )
    .required("Logo da loja obrigatório"),
});

export const SignupEntrepreneurStep03 = yup.object().shape({
  payment_method: yup
    .array()
    .min(1, "Selecione no mínimo um método de pagamento")
    .required("Selecione um método ou mais."),
});

export const SignupEntrepreneurStep04 = yup.object().shape({
  zip_code: yup
    .string()
    .required("CEP obrigatório")
    .test("CEP-valid", "CEP incompleto", (value) => {
      return !value?.includes("_");
    }),
  city: yup.string().required("Cidade obrigatória"),
  state: yup.string().required("Estado obrigatório"),
  neighborhood: yup.string().required("Bairro obrigatório"),
  street: yup.string().required("Rua obrigatória"),
  complement: yup.string().required("Complemento obrigatório"),
  number: yup.string().required("Número obrigatório"),
});

export const SignupEntrepreneurSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),

  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  email: yup.string().required("Email obrigatório").email("Email inválido"),
 
});

export const UpdateClientSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
 
  email: yup.string().email("Email inválido"),
  
 
  password: yup.string().min(8, "Mínimo de 8 caracteres"),
  city: yup.string().required("Cidade obrigatória"),
});

export const UpdateEntrepreneurSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  email: yup.string().email("Email inválido"),
  
  
  
  password: yup.string().min(8, "Mínimo de 8 caracteres"),
});

export const UpdateAdminSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
 
  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  email: yup.string().email("Email inválido"),
 
  city: yup.string().required("Cidade obrigatória"),
});

export const SignupAdminSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  
  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
  email: yup.string().required("Email obrigatório").email("Email inválido"),

});

export const AddProductSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  description: yup.string().required("Descrição obrigatório"),
  link: yup.string().url("Insira um URL válido.").nullable(),
  
  image: yup
    .mixed()
    .test(
      "is-big-file",
      "Imagem muito grande, maior que 2MB",
      checkIfFilesAreTooBig
    )
    .required("Imagem do produto obrigatória"),
   



  

  
  partner_id: yup.number().required("Selecione um parceiro"),
  
});

export const AddGiftVoucherchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  price: yup
    .number()
    .required("Preço obrigatório")
    .moreThan(0, "O preço não pode ter valores negativos"),
  description: yup.string().required("Descrição obrigatória"),
  image: yup
    .mixed()
    .test(
      "is-big-file",
      "Imagem muito grande, maior que 2MB",
      checkIfFilesAreTooBig
    )
    .required("Imagem do produto obrigatória"),
  amount: yup
    .number()
    .required("Quantidade obrigatória")
    .moreThan(0, "A quantidade não pode ter valores negativos"),
  category_id: yup.string().required("Categoria do produto obrigatória"),
});

export const UpdateProductSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  link: yup.string().url("Insira um URL válido.").nullable(),
 
  image: yup
  .mixed()
  .test(
    "is-big-file",
    "Imagem muito grande, maior que 2MB",
    checkIfFilesAreTooBig
  ),






  partner_id :yup.number().required("Selecione um  parceiro"),
  category_type_id: yup.number().required("Selecione uma categoria"),
});

export const CheckoutSchema = yup.object().shape({
  delivery_method: yup.string().required("Método de entrega obrigatório"),
  telephone: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "2",
    then: yup.string().required("Telefone obrigatório"),
  }),
  nome: yup.string().required("Nome obrigatório"),
  rua: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "Delivery",
    then: yup.string().required("Rua obrigatória"),
  }),
  bairro: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "Delivery",
    then: yup.string().required("Bairro obrigatório"),
  }),
  numero: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "Delivery",
    then: yup.string().required("Número obrigatório"),
  }),
  cep: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "Delivery",
    then: yup.string().required("CEP Obrigatório"),
  }),
  pontoReferencia: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "Delivery",
    then: yup.string(),
  }),
  payment_method: yup.string().required("Método de pagamento obrigatório"),
});

export const CheckoutLoggedSchema = yup.object().shape({
  delivery_method: yup.string().required("Método de entrega obrigatório"),
  payment_method: yup.string().required("Método de pagamento obrigatório"),
  pontoReferencia: yup.string().when("delivery_method", {
    is: (delivery_method) => delivery_method === "Delivery",
    then: yup.string(),
  }),
});

export const UpdateGiftSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  price: yup
    .number()
    .required("Preço obrigatório")
    .moreThan(0, "O preço não pode ter valores negativos"),
  description: yup.string().required("Descrição obrigatória"),
  image: yup
    .mixed()
    .test(
      "is-big-file",
      "Imagem muito grande, maior que 2MB",
      checkIfFilesAreTooBig
    ),
  amount: yup
    .number()
    .required("Quantidade obrigatória")
    .moreThan(0, "A quantidade não pode ter valores negativos"),
  category_id: yup.string().required("Categoria do produto obrigatória"),
});

export function checkIfFilesAreTooBig(files) {
  let valid = true;
  if (files) {
    // eslint-disable-next-line array-callback-return
    const size = files.size / 1024 / 1024;
    if (size > 2) {
      valid = false;
    }
  }
  return valid;
}

export const NewPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Senha necessária")
    .min(8, "Mínimo de 8 caracteres"),
  conf_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem corresponder"),
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email("Email inválido"),
});

export const LoyaltyCardSchema = yup.object().shape({
  description: yup.string().required("Descrição do bônus obrigatória"),
  status: yup.boolean().required("Status obrigatório"),
  deadline_expire: yup
    .number()
    .integer()
    .min(
      5,
      "O cartão fidelidade deve ter um prazo de expiração de pelo menos 5 dias"
    )
    .required("Prazo de validade obrigatório"),
  points: yup.number().integer().required("Quantidades de pontos obrigatório"),
});

export const RequestCheckPriceSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  telephone: yup
    .string()
    .required("Telefone obrigatório")
    .test("telephone-only", "Telefone inválido", telephoneOnly),
});
