import { formatDate } from "./formatters";
import { formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
export function currency(value) {
  return Number(value).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}
export function timeAgo(date){
  return formatDistanceToNow(new Date(date), { addSuffix: true, locale: ptBR });
}


export function discountIsValid(discount, discountDate) {
  const dt = new Date(discountDate);

  return dt >= new Date(formatDate(new Date())) && Number(discount) > 0;
}

export function validDateCupom(date) {
  if (!date) return false; // Retorna false se a data for nula ou indefinida

  const dt = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Isso irá ignorar a hora atual e comparar somente a data

  return dt >= today;
}




export function valueToRate(value) {
  const rates = ["Ruim", "Regular", "Bom", "Ótimo", "Excelente"];

  return rates[value - 1];
}

export function calculateTotal(cart) {
  const prices = [];

  for (let key in cart) {
    const p = cart[key];

    if(p.min_quantity_wholesale !== null && p.wholesale_price !== null && p.quantity >= p.min_quantity_wholesale) {
      prices.push(p.wholesale_price * p.quantity);
    } else {
      const hasDiscount = discountIsValid(p.discount, p.date_expiration) && p.priceDiscount !== null;
      const price = hasDiscount ? p.priceDiscount : p.price;
      prices.push(price * p.quantity);
    }
  }

  return currency(
    prices.reduce((a, b) => a + b),
    0
  );
}

export function textEllipsis(text, limit = 15) {
  const words = text.split(" ");
  if (words.length <= limit) {
    return text;
  }

  const limited = words.slice(0, limit);
  return limited.join(" ") + "...";
}

export const routes = {
  login: {
    client: "/login",
    poster: "/login",
    admin: "/login",
  },
  signup: {
    client: "/user",
    poster: "/entrepreneur",
    admin: "/admin",
  },
  logout: {
    client: "/logout",
    poster: "/logout",
    admin: "/logout",
  },
};
