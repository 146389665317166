import { Col, Form, Row, Button, Tabs, Tab, Image, ListGroup, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { AddProductEntrepreneur,getAllRibbonBadges, getAllTypes, getAllPartiners, getAllImagesProducts, getImage } from "../../services/api";
import { AddProductSchema } from "../../utils/validation_schemas";
import { createProductFormData } from "../../utils/payloads";
import ErrorAlert from "../ErrorAlert";
import useNotification from "../../contexts/notification";
import { formatDate } from "../../utils/formatters";
import useCategories from "../../hooks/useCategories";
export default function AddProductForm({ onSubmit }) {

  const [status, setStatus] = useState("start");
  const clearStatus = () => setStatus({ status: "start" });
  const { setMessage } = useNotification();
  const [partners, setPartners] = useState([]);
  const [images, setImages] = useState([]);
  const categories = useCategories();
  const [selectedImageId, setSelectedImageId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [key, setKey] = useState('upload');
  const [badge, setBadge] = useState([]);
  const handleSelectTab = (k) => {
    setKey(k);
    if (k === 'gallery') {
      setShowModal(true);
    }
  };


  useEffect(() => {

    getAllImagesProducts().then((data) => {

      setImages(data);
    });
  }, []);

  useEffect(() => {

    getAllRibbonBadges().then((data) => {

      setBadge(data);
    });
  }, []);

  useEffect(() => {
    getAllPartiners().then((data) => {

      setPartners(data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      image: undefined,
      previous_price: "",
      link: "",
      partner_id: undefined,
      have_cupom: "0",
      send_notifications: "0",
      title: "",
      ribbon_badges: "0",
      category_type_id: undefined,
      cupom: "",
      cupom_validity: "",
      price_on_offer: "0",
      min_quantity_wholesale: "",
      offer_price: "",

    },
    validationSchema: AddProductSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setSubmitting(true);
      console.log(values)
      await AddProductEntrepreneur(createProductFormData(values));
      setMessage({
        error: false,
        content: "Produto adicionado com sucesso",
      });
      setSubmitting(false);
      onSubmit?.();

      //setStatus(result ? "success" : "error");
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do produto</h6>
      <Form.Group className="mt-1 mb-4" controlId="formProductData">
        <Row>
          <Col xl={8} md={8}>
            <Form.Label>Nome do produto</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="name"
              placeholder="Nome do produto"
              required
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>

          <Col xl={4} md={4} sm={5}>
            <Form.Label>Preço Anterior *</Form.Label>
            <Form.Control
              className="my-1"
              type="number"
              name="previous_price"

              placeholder="Preço Anterior"
              onChange={formik.handleChange}
              value={formik.values.previous_price}
              isValid={formik.touched.previous_price && !formik.errors.previous_price}
              isInvalid={formik.errors.previous_price}

            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.previous_price}
            </Form.Control.Feedback>

          </Col>
          <Col xl={6} md={6} sm={6}>
            <Form.Label>Preço da Oferta *</Form.Label>
            <Form.Control
              className="my-1"
              type="number"
              name="offer_price"
              placeholder="Preço de Oferta"
              onChange={formik.handleChange}
              value={formik.values.offer_price}
              isValid={
                formik.touched.offer_price &&
                !formik.errors.offer_price
              }
              isInvalid={formik.errors.offer_price}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.offer_price}
            </Form.Control.Feedback>
            <Form.Check
              className="my-1"
              type="checkbox"
              name="price_on_offer"
              label="Preço em Oferta"
              onChange={(e) => {
                formik.setFieldValue("price_on_offer", e.target.checked ? "1" : "0");

              }}
              value={formik.values.price_on_offer === "1"}
            />
          </Col>
          <Form.Group>
        <h6 className="mb-3 text-primary center">Emblemas no Produto</h6>
        <fieldset>
          <h6 className="text-danger">{formik.errors.ribbon_badges}</h6>
          <Row>
            {badge.map((f) => {
              return (
                <Col sm={12} md={6} lx={6} key={f.id}>
                  <Form.Check
                    custom
                    id={`custom-checkbox-${f.id}`}
                    name="ribbon_badges"
                    type="radio"
                    isInvalid={
                      formik.errors.ribbon_badges &&
                      formik.touched.ribbon_badges
                        ? true
                        : false
                    }
                    value={f.id}
                    label={f.name}
                    required={formik.values.ribbon_badges.length === 0}
                    checked={formik.values.ribbon_badges.includes(
                      String(f.id)
                    )}
                    onChange={formik.handleChange}
                  />
                </Col>
              );
            })}
          </Row>
        </fieldset>
      </Form.Group>
          <Col xl={12} md={12} sm={12}>
            <Form.Label>Descrição *</Form.Label>
            <Form.Control
              className="my-1"
              as="textarea"
              rows={3}
              name="description"
              placeholder="Descrição do produto"
              onChange={formik.handleChange}
              value={formik.values.description}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={formik.errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Col>
          <Tabs activeKey={key} onSelect={handleSelectTab} id="uncontrolled-tab-example">
            <Tab eventKey="upload" title="Fazer Upload">

              <Form.File
                id=""
                accept="image/png, image/jpeg"
                name="image"
                lang="pt"
                label={
                  formik.values.image
                    ? formik.values.image.name
                    : "Escolher arquivo"
                }
                onChange={(e) =>
                  //console.log(e.currentTarget.files[0])
                  formik.setFieldValue("image", e.currentTarget.files[0])
                }
                isInvalid={formik.errors.image}
                feedback={formik.errors.image}
                custom
              />

            </Tab>
            <Tab eventKey="gallery" title="Escolher da galeria" >
              <Col xl={6} md={6} sm={6}>



                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                  <Modal.Body style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {images.map((image) => (
                      <div
                        key={image.id}
                        style={{
                          margin: '5px',
                          cursor: 'pointer',
                          border: image.id === selectedImageId ? '2px solid #007bff' : 'none', // Destaque da imagem selecionada
                          display: 'inline-block', // Isso garante que o elemento div se comporte como inline para centralização
                          textAlign: 'center', // Isso centraliza o conteúdo da div
                        }}
                        onClick={async () => {
                          setSelectedImageId(image);
                          setShowModal(false);
                          const imageURL = getImage(image.name, image.id);
                          console.log(`Fetching image from URL: ${imageURL}`);

                          try {
                            const response = await fetch(imageURL, { method: 'GET' });
                            const blob = await response.blob();
                            const filename = image.name || 'downloaded.jpg';
                            const file = new File([blob], filename, { type: 'image/jpeg' });

                            formik.setFieldValue("image", file);

                          } catch (error) {
                            console.error('Erro ao buscar a imagem:', error);
                          }
                        }}
                      >
                        <Image
                          src={image.url}
                          alt={image.name}
                          thumbnail
                          style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                    ))}
                  </Modal.Body>
                </Modal>


                {selectedImageId && (
                  <div >
                    <Image
                      src={selectedImageId.url}
                      thumbnail
                      style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }}
                    />
                  </div>
                )}

              </Col>
            </Tab>
          </Tabs>



          <Col xl={12} md={12} sm={12}>
            <Form.Label>Link de Oferta</Form.Label>
            <Form.Control
              className="my-1"
              type="url"
              name="link"
              placeholder="http://exemplo-de-oferta.com"
              onChange={formik.handleChange}
              value={formik.values.link}
              isValid={formik.touched.link && !formik.errors.link}
              isInvalid={formik.errors.link}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.link}
            </Form.Control.Feedback>
          </Col>


          <Col xl={6} md={6} sm={6}>
            <Form.Label>Parceiros *</Form.Label>
            <Form.Control
              as="select"
              name="partner_id"
              isValid={formik.touched.partner_id && !formik.errors.partner_id}
              isInvalid={formik.errors.partner_id}
              onChange={formik.handleChange}
              value={formik.values.partner_id}
              autoComplete="off"

              custom
            >
              <option>Selecione um parceiro</option>
              {partners.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xl={6} md={6} sm={6}>
  <Form.Control
    as="select"
    name="category_type_id"
    custom
    onChange={formik.handleChange}  // Assegura que Formik lida com mudanças de estado
    onBlur={formik.handleBlur}     // Opcional, para validação ao desfocar
    value={formik.values.category_type_id}  // Assegura que o valor atual é refletido
  >
    <option value="">Selecione uma subcategoria</option>
    {categories.map((category) => (
      <optgroup key={category.id} label={category.name}>
        {category.category_types.map((subCategory) => (
          <option key={subCategory.id} value={subCategory.id}>
            {subCategory.name}
          </option>
        ))}
      </optgroup>
    ))}
  </Form.Control>
</Col>





          <Col xl={6} md={6} sm={6}>
            <Form.Label>Cupom *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="cupom"
              placeholder="Cupom Disponivel"
              onChange={formik.handleChange}
              value={formik.values.cupom}
              isValid={formik.touched.cupom && !formik.errors.cupom}
              isInvalid={formik.errors.cupom}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.cupom}
            </Form.Control.Feedback>
          </Col>

          <Col xl={6} md={6} sm={6}>
            <Form.Label>Data de expiração do Cupom *</Form.Label>
            <Form.Control
              className="my-1"
              type="date"
              name="cupom_validity"
              min={formatDate(new Date())}
              placeholder="Data de expiração do Cupom"
              onChange={formik.handleChange}
              value={formik.values.cupom_validity}
              isValid={
                formik.touched.cupom_validity &&
                !formik.errors.cupom_validity
              }
              isInvalid={formik.errors.cupom_validity}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.cupom_validity}
            </Form.Control.Feedback>
          </Col>



        </Row>

        {status === "error" && (
          <ErrorAlert msg="Um erro ocorreu." onClose={clearStatus} />
        )}
        <Col>
        <Form.Check
              className="my-1"
              type="checkbox"
              name="send_notifications"
              label="Enviar Notificações?"
              onChange={(e) => {
                formik.setFieldValue("send_notifications", e.target.checked ? "1" : "0");

              }}
              value={formik.values.send_notifications === "1"}
            />
            { formik.values.send_notifications == "1" &&(
          
          <Form.Group controlId="formTitle">
           
            <Form.Control
              type="text"
              name="title"
              placeholder="Digite o título"
              onChange={formik.handleChange}
              value={formik.values.title}
            />
          </Form.Group>
        
      )}
          <Row>
            <Button
              className="my-2"
              variant="primary"
              type="submit"
              disabled={submitting}
            >
              Adicionar
            </Button>
          </Row>
          
        </Col>
      </Form.Group>
    </Form>
  );
}
