import { currency, discountIsValid, timeAgo } from "../../utils/mappers";
import { Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FiGrid } from "react-icons/fi";
import { RatingStars } from "../RatingStars";
import "./styles.scss";
import { getImage } from "../../services/api";




export default function ProductCard({ product }) {
  const history = useHistory();

  const showDiscountInfo = product.price_on_offer == 1 ? true : false;


  return (
    <Card
      className="my-1 card-product"
      title={`Ver detalhes de ${product.name}`}
      onClick={() => {
        return history.push(`/produto/${product.id}`);
      }}
     
    >
      <Image src={(product.image_url)} className="product-card-image" />
      {product.partner && product.partner.image_url && (
        <div className="partner-logo-container">
          <Image src={product.partner.image_url} className="partner-logo" alt={`Logo de ${product.partner.name}`} />
        </div>
      )}
   {product.ribbon_badges && product.ribbon_badges.length > 0 && product.ribbon_badges.map((badge) => (
        <span key={badge.id} className="ribbon-badge" style={{
            position: 'absolute',
            top: '5px', // Posicionado um pouco para baixo para simular o estilo na imagem
            left: '1px', // Posicionado à direita
            backgroundColor: badge.color,
            color: '#fff',
            padding: '5px 20px',
            
            border: '1px solid #ddd',
            borderRadius: '5px',
            overflow: 'hidden',
             textTransform:'uppercase',
            fontSize: '14px',
            fontWeight:'bold',
            // Texto centralizado
            boxShadow: '5px 2px 5px rgba(0,0,0,0.4)',
            zIndex: 10
        }}>
           {badge.name}
         
           </span>
      ))}



      <Card.Body>
        <Card.Title className="" style={{ fontWeight: 'bold', fontSize: '17px', color: 'black' }}>


          {product.name}



        </Card.Title>


        Postado {timeAgo(product.created_at)}


        {product.previous_price && showDiscountInfo && (
          <p className="old-price">{currency(product.previous_price)}</p>
        )}
        <div className=" price h6" style={{ fontWeight: 'bold', fontSize: '25px', color: 'green' }}>
          {currency(
            showDiscountInfo ? product.offer_price : product.previous_price
          )}

          {showDiscountInfo && (
            <span className="h6 bg-primary text-light p-1 rounded ml-2" style={{ fontSize: '11px' }}>Oferta </span>
          )}
        </div>




        {product.categoryName && (

          <div className="d-flex justify-content-center align-items-center">
            <FiGrid className="text-muted" size={16} />
            <span className="ml-1 text-muted">{product.categoryName}</span>
          </div>

        )}

      </Card.Body>
    </Card>
  );
}
