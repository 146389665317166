import { Form as FormikForm, Field, Formik } from "formik";
import { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, Redirect, useHistory } from "react-router-dom";
import useAuth from "../../contexts/auth";
import { cpfCnpj } from "../../utils/formatters";
import { LoginSchema } from "../../utils/validation_schemas.js";
import ErrorAlert from "./../../components/ErrorAlert";
import Footer from "./../../components/Footer";
import qs from "qs";
import { FaArrowAltCircleRight } from "react-icons/fa";
import "./styles.scss";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import {
  loginGoogle,
} from "../../services/api";
import useStorage from "../../hooks/useLocalStorage.js";
export default function Login({ match, location }) {
  const { user, login } = useAuth();
  const [status, setStatus] = useState({ status: "start" });
  const history = useHistory();
  const next = qs.parse(location.search, { ignoreQueryPrefix: true }).next;
  const ent = qs.parse(location.search, { ignoreQueryPrefix: true }).ent;
  const [userx, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useStorage("@app_mercadim:token", null);

  const clearStatus = () => setStatus({ status: "start" });

  useEffect(() => {

    // Clean-up function
    return () => {
      setStatus({});
      // Remove the script when the component unmounts

    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoogleSuccess = async (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log('Decoded JWT:', decoded);

    // Criar um objeto com as informações do usuário para enviar ao backend
    const userGoogleData = {
      email: decoded.email,
      name: decoded.name,
      // Você não precisa da senha aqui, pois a autenticação é pelo Google
    };

    // Defina o estado de carregamento
    setStatus({ status: "loading" });

    try {
      // Chamar a função de login com um segundo argumento para indicar que é um login do Google
      const status = await login(userGoogleData, true); // 'true' indica que é um login do Google

      // Atualizar o estado com base na resposta
      if (status.signed) {
        setStatus({ status: "success" });
        // Redirecione ou atualize a UI conforme necessário
        // history.push('/algum_caminho');
      } else {
        setStatus({ status: "error", errors: status.errors });
        // Exiba uma mensagem de erro se necessário
      }
    } catch (error) {
      //setStatus({ status: "error", errors: "Ocorreu um erro ao processar o login." });
      //console.error('Erro ao processar o login:', error);
    }
  };




  const renderLoading = () => {
    if (status.status !== "loading") {
      return null;
    }





    return (
      <div className="d-flex justify-content-center">
        <Spinner variant="primary" animation="border" size="sm" />
      </div>
    );
  };

  if (user) {
    if (next) {
      return <Redirect to={`/checkout?ent=${ent}`} />;
    }
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet title="cacadoresdeofertas: Acessar" />
      <Container fluid="md" className="py-4">
        <Formik
          enableReinitialize
          validationSchema={LoginSchema}
          initialValues={{
            email: "",
            password: "",

            show_password: false,
          }}
          onSubmit={async (values) => {
            console.log("ok");
            setStatus({ status: "loading" });

            const status = await login(values, false);
            setStatus(
              status.signed
                ? { status: "success" }
                : { status: "error", errors: status.errors }
            );
          }}
        >
          {({ values, errors, touched }) => (
            <Form noValidate as={FormikForm}>
              <Row className="justify-content-center">
                <Col sm={8} lg={6} className="align-self-center">
                  <h3 className="text-center text-primary pt-4 mb-2">Entrar</h3>
                  {/*
                  <p className="text-muted text-center mb-5">
                    Não possui conta? <Link to="/cadastro">Cadastre-se</Link>
          </p>*/}
                  {renderLoading()}
                  {console.log(status.status)}
                  {status.status === "error" && (
                    <ErrorAlert
                      msg={Object.values(status.errors)[0]}
                      onClose={clearStatus}
                    />
                  )}

                  <Form.Group className="mb-4" controlId="formCpfCnpj">
                    <Form.Control
                      as={Field}
                      title="Digite o E-mail"
                      type="text"
                      name="email"
                      size="lg"

                      placeholder="E-mail"
                      required
                      value={values.email}

                      isValid={touched.email && !errors.email}
                      isInvalid={
                        errors.email && touched.email ? true : false
                      }
                      autoComplete="on"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formSenha">
                    <Form.Control
                      as={Field}
                      label="Senha*"
                      required="Campo necessário"
                      size="lg"
                      name="password"
                      type={values.show_password ? "text" : "password"}
                      isValid={touched.password && !errors.password}
                      isInvalid={
                        errors.password && touched.password ? true : false
                      }
                      placeholder="Sua senha"
                      autoComplete="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formShowPassword" as={Row}>
                    <Col>
                      <Link to="/recuperar">Esqueceu sua senha?</Link>
                    </Col>
                    <Col xs="auto">
                      <Form.Check
                        as={Field}
                        id="show-password-1"
                        name="show_password"
                        type="switch"
                        label="Mostrar senha"
                        checked={values.show_password}
                        custom
                      />
                    </Col>
                  </Form.Group>
                  <Button
                    className="my-1"
                    variant="primary"
                    type="submit"
                    block
                  >
                    Entrar
                  </Button>
                  {next && (
                    <>
                      <p className="text-muted text-center">ou</p>
                      <Button
                        variant="outline-primary"
                        block
                        onClick={() => history.push(`/checkout?ent=${ent}`)}
                      >
                        Continuar sem login <FaArrowAltCircleRight />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

        <Row className="justify-content-center">
          <Col sm={8} lg={6} className="align-self-center">
            <GoogleLogin
              onSuccess={credentialResponse => {
                handleGoogleSuccess(credentialResponse)
                
              }}
              onError={(error) => {
                
                 setStatus({status: "error", errors: error })
              }}
            />
          </Col>
        </Row>




      </Container>
      <div id="app"> {/* ou 'root', dependendo do ID do elemento principal do seu aplicativo */}
        <div className="content">
          {/* Todo o conteúdo da página, exceto o rodapé, vai aqui */}
        </div>
        <Footer /> {/* Footer deve estar fora do .content mas dentro do #app */}
      </div>

    </>
  );
}