import RedirectContainer from "../../../components/RedirectContainer";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  InputGroup,
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { ImLocation2 } from "react-icons/im";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { getAllLocations, searchLocation } from "../../../services/api";
import PaginationComponent from "../../../components/Pagination";
import useNotification from "../../../contexts/notification";

export default function Locations() {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const { setMessage } = useNotification();

  function handleQueryLocation(e) {
    e.preventDefault();
    searchLocation(query, page).then(setData);
  }

  useEffect(() => {
    if (query.length === 0) {
      getAllLocations(page).then(
        (response) => {
          setData(response);

          setIsLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    } else {
      setPage(1);
    }
  }, [query, page, setMessage]);

  return (
    <>
      <RedirectContainer role="admin">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin />

            <div className="content-panel">
              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <>
                  <div
                    className="d-flex justify-content-between py-3 flex-column 
              flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center align-items-sm-start 
              align-items-md-start align-items-lg-start align-items-xl-start"
                  >
                    <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                      <ImLocation2 className="mr-2" />
                      Localidades
                    </h5>
                  </div>
                  <Row className="d-flex justify-content-between">
                    <Col lg={4}>
                      <Form >
                        <Form.Group className="my-3 d-flex">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="Buscar uma localidade"
                              required
                              minLength="3"
                              onChange={(e) => setQuery(e.target.value)}
                              onKeyUp={handleQueryLocation}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col lg={3}></Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Table striped bordered responsive size="sm">
                        <thead>
                          <tr>
                            <th>Cidade</th>
                            <th>Estado</th>
                          </tr>
                        </thead>
                        {data.data.length === 0 && (
                          <h5 className="text-muted text-center my-5">
                            Sem resultados
                          </h5>
                        )}
                        <tbody>
                          {data.data.map((location) => (
                            <tr key={location.id} className="w-25">
                              <td>{location.name}</td>
                              <td>{location.uf}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-end">
                        <PaginationComponent
                          pagination={data}
                          onClickPage={(page) => {
                            setPage(page);
                            if (query.length > 0) {
                              searchLocation(query, page).then(setData);
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </RedirectContainer>
    </>
  );
}
