import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Image, Button, Media } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getProduct, getAllPartiners, getProductReviews, getUser, deleteReview } from "../../../services/api";
import { currency, validDateCupom, valueToRate } from "../../../utils/mappers";
import "./styles.scss";
import useCart from "../../../contexts/cart";
import { FiTruck } from "react-icons/fi";
import { MdEdit, MdWhatsapp, MdCheck, MdDelete } from "react-icons/md";
import { FaTelegramPlane, FaBolt, FaCut } from 'react-icons/fa';
import LoadingIndicator from "../../../components/LoadingIndicator";
import NotFound from "../../NotFound";
import Footer from "../../../components/Footer";
import { ModalCentered } from "../../../components/AvaliationModal";
import useAuth from "../../../contexts/auth";
import { RatingStars } from "../../../components/RatingStars";

import { useHistory } from "react-router-dom";
import useNotification from "../../../contexts/notification";
import ConfirmCheckPriceModal from "./ConfirmCheckPriceModal";

export default function Product() {
  const { id } = useParams();
  const { user } = useAuth();
  const [productData, setProductData] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);

  const { addToCart, productExists } = useCart();
  const [partners, setPartners] = useState([]);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const { setMessage } = useNotification();
  const [editRate, setEditRate] = useState(null);
  const [rates, setRates] = useState([]); // Supomos que você tem um estado para as avaliações

  const history = useHistory();

  const [showReviewModal, setShowReviewModal] = useState(false);
  const handleEdit = (rate) => {
    setEditRate(rate);
    setShowReviewModal(true);
  };



  function refresh() {

    getProductReviews(id).then(
      (data) => {
        setReviews(data);
        setLoading(false);
      },
      (err) => {
        setReviews([]);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    getProduct(id).then(
      (data) => {
        setProductData(data);
        getAllPartiners().then((data) => {
          setPartners(data)

        })

        getProductReviews(id).then(
          (data) => {

            setReviews(data);
            setLoading(false);
          },
          (err) => {
            setReviews([]);
            setLoading(false);
          }
        );
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [id]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (productData === null) {
    return <NotFound isComponent />;
  }
  const CouponCopyButton = ({ cupom }) => {

    const [isCopied, setIsCopied] = useState(false); // Estado para acompanhar se o cupom foi copiado

    const handleCopyClick = () => {
      navigator.clipboard.writeText(cupom).then(() => {
        setIsCopied(true); // Atualiza o estado para verdadeiro, indicando que o cupom foi copiado
        setTimeout(() => setIsCopied(false), 2000); // Depois de 2 segundos, volta ao ícone original
      }).catch(err => {
        console.error('Não foi possível copiar o cupom', err);
      });
    };

    return (
      <Button onClick={handleCopyClick} className="coupon-copy-button">
        {isCopied ? <MdCheck /> : <FaCut />} {cupom}
      </Button>
    );
  };

  const showDiscount =
    productData.product.price_on_offer == 1 ? true : false;
  const cupom = productData.product.have_cupom == 1 ? true : false;
  const valDate = productData.product.cupom_validity !== null ? true : false;


  const hasValidCupom = cupom
  const formattedCupomValidity = valDate
    ? new Date(productData.product.cupom_validity).toLocaleDateString('pt-BR')
    : '';




  return (
    <>

      <Container className="product-page-container my-5">
        <Row>
          <Col md={8} className="product-details">
            <Row>
              <Col lg={6} className="product-image-column">
                <Image
                  src={productData.product.image_url}
                  rounded
                  fluid
                  className="image_product"
                />
                <div className="partner_image_container">
                  <Image
                    src={productData.producerStore[0].image_url} // Supondo que esta seja a propriedade onde a imagem do parceiro é armazenada
                    rounded
                    fluid
                    className="partner-logo" // Adicione uma nova classe para estilização se necessário
                  />
                </div>
              </Col>
              <Col lg={6} className="product-info-column">
                <h2 className="product-title">{productData.product.name}</h2>
                <div className="product-price-and-buy">


                  <span className="product-price">{currency(
                    showDiscount ? productData.product.offer_price : productData.product.previous_price
                  )}</span>

                  <div className="price-change-warning" style={{ backgroundColor: 'rgb(225, 245, 254)', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                    <p style={{ margin: '0px', color: 'rgb(13, 71, 161)', fontSize: '14px' }}>
                      ⚠️ O valor poderá alterar a qualquer momento!
                    </p>
                  </div>
                  <div className="mb-2">
                    <Button variant="primary" size="lg" className="buy-now-btn" href={productData.product.link}>Comprar agora</Button>
                  </div>
                  {hasValidCupom && (
                    <div>
                      <CouponCopyButton cupom={productData.product.cupom} />
                    </div>
                  )}
                  {valDate && (
                    <h6 className="">Validade do Cupom: {
                      formattedCupomValidity
                    }</h6>
                  )}
                </div>
                <p className="product-description">{productData.product.description}</p>


                <div className="seller-info">
                  <span>Loja Parceira </span>
                  <Link to={`/loja/${productData.producerStore[0].slug}`}>
                    {productData.producerStore[0].name}
                  </Link>
                </div>
              </Col>




            </Row>
          </Col>

          <Col md={4} className="store-logos-column">
            <div className="promo-section">
              <h2>Receba as melhores promoções</h2>
              <hr style={{ background: 'yellow' }} />
              <a href="https://chat.whatsapp.com/L5L4mDl2X7tA3Z1cv23mD7" rel="noopener noreferrer" className="promo-button-link">
                <Button style={{ background: 'rgb(59, 193, 77)' }}>
                  <MdWhatsapp /> WhatsApp
                </Button>
              </a>
              <a href="https://t.me/cuponsjersu" rel="noopener noreferrer" className="promo-button-link">
                <Button style={{ background: 'rgb(18, 113, 198)' }}>
                  <FaTelegramPlane /> Telegram
                </Button>
              </a>
            </div>


            <h1>Lojas</h1>
            <div className="store-logos-container">
              {partners.map((partner, index) => (
                <Link to={`/loja/${partner.slug}`} key={index} className="store-logo-link">
                  <Image
                    src={partner.image_url}
                    alt={partner.name}
                    className="store-logo"
                  />
                </Link>
              ))}
            </div>
          </Col>




        </Row>
        <Button
          size="sm"
          variant="outline-primary"
          className="mt-5"
          onClick={() => setShowReviewModal(true)}
        >
          Comentar produto
          <MdEdit />
        </Button>


        <hr />
        {reviews.Avaliacoes.map((rate, index) => {
          return <UserRate key={index} rate={rate} onEdit={handleEdit} user={user} refresh={refresh} />;
        })}
        {reviews.Avaliacoes.length === 0 && (
          <p className="text-muted text-center">
            Não há comentários para este produto
          </p>
        )}

      </Container>
      <ModalCentered
        id={id}
        show={showReviewModal}
        onHide={() => {
          refresh();
          setShowReviewModal(false);
          setEditRate(null);
        }}
        editRate={editRate}
      />
      <div className="footer">
        <Footer />
      </div>
    </>
  );
}

export function UserRate({ rate, onEdit, onDelete, user, refresh }) {

  const handleDelete = () => {
    deleteReview(rate).then(() => {
      refresh();
    });
  };

  const renderRating = () => {
    return (
      <div className="d-flex align-items-center">
        <span className="ml-2">{valueToRate(rate.userRate)}</span>
      </div>
    );
  };
  const idRate = rate.user_id != null ? rate.user_id.id : null;
  const canEditOrDelete = user && (user.id === idRate || user.type === 'admin');

  return (
    <Media className="my-2">
      <Media.Body>
        {renderRating()}
        <div className="d-flex align-items-center">
          <p className="my-0">
            {rate.user_id !== null ? rate.user_name : rate.user_name}
          </p>
          {canEditOrDelete && (
            <div className="d-flex ml-2">
              <Button size="sm" variant="outline-primary" onClick={() => onEdit(rate)} className="mr-1">
                Editar <MdEdit />
              </Button>
              <Button size="sm" variant="outline-danger" onClick={handleDelete} className="mr-1">
                Excluir
                <MdDelete />
              </Button>
            </div>
          )}
        </div>
        <p className="text-muted">{rate.review}</p>
      </Media.Body>
    </Media>
  );
}
