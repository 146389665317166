import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";
import { useHistory } from "react-router";

export default function NotFound({ isComponent = false }) {
  const history = useHistory();

  return (
    <>
      <div className="not-found d-flex align-items-center">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col className=" text-center" lg={6}>
              <h1 className="display-4 text-primary">Erro 404</h1>
              <span>A página que você está tentando accessar foi</span>
              <span> removida ou está inacessível.</span>
              <p>
                {isComponent && (
                  <Button
                    variant="primary"
                    className="mt-3 text-white"
                    onClick={history.goBack}
                  >
                    VOLTAR
                  </Button>
                )}
                {!isComponent && (
                  <Button
                    variant="primary"
                    className="mt-3 text-white"
                    as={Link}
                    to="/"
                  >
                    IR PARA INÍCIO
                  </Button>
                )}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
