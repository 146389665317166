import { Modal, Button, Form,Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { reviewProduct,updateReview } from "../../services/api";
import useAuth from "../../contexts/auth";
export function ModalCentered({ onSend,editRate, ...props }) {
  const [note, setNote] = useState("1");
  const [review, setReview] = useState("");
  const [user_name,setName] =  useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();


  useEffect(() => {
    if (editRate) {
      setNote(editRate.note.toString());
      setReview(editRate.review);
      setName(editRate.user_name);
    }
  }, [editRate]);
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      note: note,
      review: review,
      user_name: user_name ?? null,
      product_id: Number(props.id),
    };

    if (editRate) {
      updateReview(editRate.id, requestData).then(() => {
        props.onHide();
        setLoading(false);
        setNote("1");
        setReview("");
      });
    } else {
      reviewProduct(requestData).then(() => {
        props.onHide();
        setLoading(false);
        setNote("1");
        setReview("");
      });
    }
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-primary">{editRate ? "Editar Comentário" : "Comente este produto"}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        {!user && (
            <Col xl={8} md={8}>
              <Form.Label>Seu Nome</Form.Label>
              <Form.Control
                className="my-1"
                type="text"
                name="user_name"
                placeholder="Nome do Usuário"
                
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          )}
        
          
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              name="review"
              value={review}
              placeholder="Escreva sua opinião"
              onChange={(e) => setReview(e.target.value)}
              required
            />
          </Form.Group>
          <Button className="mr-auto" type="submit" disabled={loading}>
          {editRate ? "Atualizar" : "Comentar"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function AvaliationModal() {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setModalShow(true);
    }, 5000);
  }, []);

  return (
    <>
      <ModalCentered show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
