import RedirectContainer from "../../../components/RedirectContainer";
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import {
  MdCheckCircle,
  MdDelete,
  MdEdit,
  MdHighlightOff,
} from "react-icons/md";
import { useEffect, useState } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import useNotification from "../../../contexts/notification";
import {
  getMyLoyaltyCards,
  deleteLoyaltyCard,
  disableAllLoyaltyCards,
  activateLoyaltyCard,
} from "../../../services/api";
import { FaGratipay } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import AddLoyaltyCardForm from "../../../components/AddLoyaltyCardForm";
import UpdateLoyaltyCardForm from "../../../components/UpdateLoyaltyCardForm";

export default function LoyaltyCards() {
  const [loyaltyCards, setLoyaltyCards] = useState({});
  const [selectedLoyaltyCard, setSelectedLoyaltyCard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const { setMessage } = useNotification();

  const refresh = () => {
    setIsLoading(true);
    getMyLoyaltyCards().then(
      (data) => {
        setLoyaltyCards(data);
      },
      (err) =>
        setMessage({
          error: true,
          content: "Ocorreu um erro, tente novamente depois.",
        })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getMyLoyaltyCards().then(
      (data) => {
        setLoyaltyCards(data);
        setIsLoading(false);
      },
      (err) =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
    );
  }, [setMessage]);

  useEffect(() => {
    if (selectedLoyaltyCard) setModalShowEdit(true);
  }, [selectedLoyaltyCard]);

  function ModalAddLoyaltyCard(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar Cartão Fidelidade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddLoyaltyCardForm onSubmit={props.onHide} />
        </Modal.Body>
      </Modal>
    );
  }

  function ModalEditLoyaltyCard(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar Cartão Fidelidade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateLoyaltyCardForm
            loyaltyCardId={selectedLoyaltyCard}
            onSubmit={props.onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <RedirectContainer role="poster">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin={false} />
            <div className="content-panel">
              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <>
                  <div className="py-3">
                    <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                      <FaGratipay className="mr-2" />
                      Cartão Fidelidade
                    </h5>
                  </div>

                  <Row className="justify-content-end mb-3">
                    <Col xs={"auto"}>
                      <Button
                        onClick={() => setModalShowAdd(true)}
                        className="btn btn-primary d-flex align-items-center text-white"
                      >
                        <span className="mr-2">Adicionar</span>
                        <IoIosAddCircle style={{ fontSize: "1.6em" }} />
                      </Button>
                    </Col>
                    <Col xs={"auto"}>
                      <Button
                        onClick={async () => {
                          let conf = window.confirm(
                            "Esta ação desativará o cartão fidelidade da loja."
                          );
                          if (conf) {
                            await disableAllLoyaltyCards().then(
                              () => {
                                setMessage({
                                  error: false,
                                  content:
                                    "Cartão fidelidade desativado com sucesso.",
                                });
                                refresh();
                              },
                              () => {
                                setMessage({
                                  error: true,
                                  content:
                                    "Ocorreu um erro, tente novamente mais tarde.",
                                });
                              }
                            );
                          }
                        }}
                        className="btn btn-info d-flex align-items-center text-white"
                      >
                        <span className="mr-2">Desativar</span>
                        <MdHighlightOff style={{ fontSize: "1.6em" }} />
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <Table striped bordered responsive size="sm">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Descrição</th>
                            <th>Pontos</th>
                            <th>Expiração (Dias)</th>
                            <th>Total Distribuido</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loyaltyCards.map((card) => (
                            <tr key={card.id}>
                              <td>
                                {card.status === "disponivel"
                                  ? "Ativado"
                                  : "Desativado"}
                              </td>
                              <td>{card.description}</td>
                              <td>{card.points}</td>
                              <td>{card.deadline_expire}</td>
                              <td>{card.loyalty_card_client_count}</td>
                              <td>
                                {/* <Container fluid> */}
                                  <Row
                                    noGutters
                                    className="justify-content-between"
                                  >
                                    <Col xs={"auto"}>
                                      <Button
                                        disabled={card.status === "disponivel"}
                                        onClick={async () => {
                                          let active = window.confirm(
                                            "Esta ação ativará este cartão fidelidade."
                                          );
                                          if (active) {
                                            await activateLoyaltyCard(
                                              card.id
                                            ).then(
                                              () => {
                                                setMessage({
                                                  error: false,
                                                  content:
                                                    "Cartão fidelidade ativado com sucesso.",
                                                });
                                                refresh();
                                              },
                                              () => {
                                                setMessage({
                                                  error: true,
                                                  content:
                                                    "Ocorreu um erro, tente novamente mais tarde.",
                                                });
                                              }
                                            );
                                          }
                                        }}
                                        size="sm"
                                        variant="primary"
                                        className="text-white d-flex align-items-center"
                                      >
                                        <MdCheckCircle
                                          style={{ fontSize: "1.4em" }}
                                        />{" "}
                                        Ativar
                                      </Button>
                                    </Col>

                                    <Col xs={"auto"}>
                                      <Button
                                        onClick={() => {
                                          setSelectedLoyaltyCard(card.id);
                                        }}
                                        size="sm"
                                        variant="warning"
                                        className="text-white d-flex align-items-center"
                                      >
                                        <MdEdit style={{ fontSize: "1.4em" }} />
                                        Editar
                                      </Button>
                                    </Col>

                                    <Col xs={"auto"}>
                                      <Button
                                        onClick={async () => {
                                          let conf = window.confirm(
                                            "Esta ação deletará o cartão fidelidade."
                                          );
                                          if (conf) {
                                            await deleteLoyaltyCard(
                                              card.id
                                            ).then(
                                              () => {
                                                setMessage({
                                                  error: false,
                                                  content:
                                                    "Cartão fidelidade deletado com sucesso.",
                                                });
                                                refresh();
                                              },
                                              () => {
                                                setMessage({
                                                  error: true,
                                                  content:
                                                    "Ocorreu um erro, tente novamente mais tarde.",
                                                });
                                              }
                                            );
                                          }
                                        }}
                                        size="sm"
                                        variant="danger"
                                        className="text-white d-flex align-items-center"
                                      >
                                        <MdDelete
                                          style={{ fontSize: "1.4em" }}
                                        />
                                        Excluir
                                      </Button>
                                    </Col>
                                  </Row>
                                {/* </Container> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {loyaltyCards.length === 0 && (
                        <h5 className="text-muted text-center my-5">
                          Sem resultados
                        </h5>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>

        <ModalAddLoyaltyCard
          show={modalShowAdd}
          onHide={() => {
            setModalShowAdd(false);
            refresh();
          }}
        />
        <ModalEditLoyaltyCard
          show={modalShowEdit}
          onHide={() => {
            setModalShowEdit(false);
            setSelectedLoyaltyCard(null);
            refresh();
          }}
        />
      </RedirectContainer>
    </>
  );
}
