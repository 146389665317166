
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Image,
  Modal,
  InputGroup,

} from "react-bootstrap";
import Checkbox from '@material-ui/core/Checkbox';

import { MdDelete, MdEdit, MdContentCopy } from "react-icons/md";
import { useState, useEffect } from "react";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import LoadingIndicator from "../../../components/LoadingIndicator";
import AddProductForm from "../../../components/AddProductForm";
import UpdateProductForm from "../../../components/UpdateProductForm";
import PaginationComponent from "../../../components/Pagination";
import { currency, discountIsValid } from "../../../utils/mappers";
import {
  getAllProductsAdmin,
  deleteProduct,
  searchProductsAdmin,
  duplicateOffer,
  getImage,
  deletesProducts
} from "../../../services/api";
import { Helmet } from "react-helmet";
import useNotification from "../../../contexts/notification";

export default function Products() {
  const [results, setResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterPromotion, setFilterPromotion] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [queryProduct, setQueryProduct] = useState("");
  const [page, setPage] = useState(1);
  const { setMessage } = useNotification();
  const [selectedProducts, setSelectedProducts] = useState([]);

  const filter = (data) => data.data.filter((p) => p.priceDiscount != null);

  const refresh = () => {
    const error = {
      error: true,
      content: "Erro ao recarregar. Tente novamente mais tarde",
    };

    if (queryProduct) {
      searchProductsAdmin(queryProduct, page).then(
        (data) => {
          if (filterPromotion) {
            data.data = filter(data);
          }
          setResults(data);
        },
        () => setMessage(error)
      );
    } else {
      getAllProductsAdmin(page).then(
        (data) => {
          if (filterPromotion) {
            const products = filter(data);
            data = products;
          }
          setResults(data);
        },
        () => setMessage(error)
      );
    }
  };

  useEffect(() => {
    if (queryProduct.length === 0) {
      getAllProductsAdmin(page).then(
        (data) => {
          if (filterPromotion) {
            const products = filter(data);
            data = products;
          }
          setResults(data);
          setIsLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    } else {
      setPage(1);
    }
  }, [queryProduct, page, filterPromotion, setMessage]);

  useEffect(() => {
    if (selectedProduct) {
      setModalShowEdit(true);
    }
  }, [selectedProduct]);

  function ModalAddProduct(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-primary">Adicionar Produto</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProductForm onSubmit={props.onHide} />
        </Modal.Body>
      </Modal>
    );
  }

  function ModalEditProduct(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar Produto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateProductForm
            productId={selectedProduct}
            onSubmit={props.onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }

  // function handleQueryProduct(e) {
  //   e.preventDefault();
  //   searchProduct(queryProduct, page).then(setResults, () =>
  //     setMessage()
  //   );
  // }

  function handleQueryProductEntrepreneur(e) {
    e.preventDefault();
    searchProductsAdmin(queryProduct, page).then(setResults, () =>
      setMessage()
    );
  }

  const deleteSelectedProducts = async () => {
    if (window.confirm("Tem certeza que deseja deletar os produtos selecionados?")) {
      await deletesProducts(selectedProducts);
      setMessage({
        error: false,
        content: "Produtos deletados com sucesso",
      });
      setSelectedProducts([]);
      refresh(); // Supondo que você tenha uma função para atualizar a lista de produtos
    }
  };


  return (
    <>
      <Helmet title="Jersu Indica: Produtos Empreendedor" />
      <Container fluid>
        <div>
          <SidebarPanelNavigation isAdmin={true} />
          <div className="content-panel">
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <>
                <Row className="py-3 justify-content-between">
                  <Col md={6}>
                    <Form>
                      <Form.Group className="mb-2" controlId="">
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Nome do produto"
                            required
                            value={queryProduct}
                            onKeyUp={handleQueryProductEntrepreneur}
                            onChange={(e) => setQueryProduct(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </InputGroup>
                      </Form.Group>

                    </Form>
                  </Col>
                  <Col className="col-auto">
                    <Button style={{ marginRight: '10px' }}
                      variant="danger"
                      onClick={deleteSelectedProducts}

                      disabled={selectedProducts.length === 0} // Desabilita se nenhum produto estiver selecionado
                    >
                      Deletar Produtos Selecionados
                    </Button>

                    <Button
                      variant="primary"
                      onClick={() => setModalShow(true)}
                      className="mr-md-3 mr-lg-3 mr-0 mr-sm-0 mb-2 mb-sm-2 mb-md-0 mb-lg-0"
                    >
                      ADICIONAR PRODUTO
                    </Button>



                  </Col>
                </Row>

                <Row className="d-flex flex-column">
                  <Col md={9}>
                    {results.length === 0 && (
                      <h5 className="text-muted text-center my-5">
                        Sem resultados
                      </h5>
                    )}
                    {results.map((prod) => (
                      <Card className="my-3" key={prod.id}>
                        <Card.Header>
                          <div>
                            <div className="d-flex justify-content-between flex-column flex-sm-column flex-md-row flex-lg-row align-items-center align-items-sm-center align-items-md-start align-items-lg-start">
                              <span style={{ fontSize: "0.9em" }}>
                                N°: {prod.id}
                              </span>
                              <span style={{ fontSize: "0.9em" }}>
                                Parceiro:
                                {prod.partner.name}
                                <Button
                                  size="sm"
                                  onClick={() => {
                                    setSelectedProduct(prod.id);
                                  }}
                                  className="btn-warning ml-2"
                                >
                                  <MdEdit
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "1.6em",
                                    }}
                                    className="text-white"
                                  />
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={async () => {
                                    if (window.confirm("Tem certeza que deseja deletar este produto ?")) {
                                      await deleteProduct(prod);
                                      setMessage({
                                        error: false,
                                        content: "produto deletado com sucesso",
                                      });
                                      refresh();
                                    }
                                  }}
                                  className="btn-danger ml-2"
                                >
                                  <MdDelete
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "1.6em",
                                    }}
                                    className="text-white"
                                  />
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={async () => {
                                    if (window.confirm("Tem certeza que deseja duplicar este produto ?")) {
                                      const status = await duplicateOffer(prod);
                                      if (status && status.data && status.data.duplicatedOffer) {
                                        console.log(status.data.duplicatedOffer.id);
                                        setMessage({
                                          error: false,
                                          content: "produto duplicado com sucesso",
                                        });

                                        refresh();
                                        setTimeout(() => {
                                          setSelectedProduct(status.data.duplicatedOffer.id);
                                        }, 2000);
                                      } else {
                                        setMessage({
                                          error: true,
                                          content: "Erro ao duplicar o produto",
                                        });
                                      }


                                    }

                                  }}
                                  className="btn-secondary ml-2"
                                >
                                  <MdContentCopy // Este é um ícone de exemplo, substitua por um ícone de duplicar da sua escolha
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "1.6em",
                                    }}

                                  />
                                </Button>
                                <Checkbox
                                  checked={selectedProducts.includes(prod.id)}
                                  onChange={e => {
                                    const newSelectedProducts = e.target.checked
                                      ? [...selectedProducts, prod.id]
                                      : selectedProducts.filter(id => id !== prod.id);
                                    setSelectedProducts(newSelectedProducts);

                                    console.log(newSelectedProducts)
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center align-items-sm-center align-items-md-start align-items-lg-start">
                            <div>
                              <Image
                                src={prod.image_url}
                                className="border"
                                style={{ width: "220px", height: "200px", objectFit: "cover" }}
                                rounded
                              />
                            </div>
                            <div className="d-flex flex-column ml-md-3 ml-lg-3 ml-0 ml-sm-0">
                              <h5 className="text-primary text-center text-md-left text-lg-left mt-3 mt-sm-3 mt-md-0 mt-lg-0">
                                {prod.name}
                              </h5>
                              <div className="display-truncate">
                                <p className="description">{prod.description}</p>
                              </div>
                              <div>
                                {prod.previous_price && (
                                  <h4>Preço Anterior: {currency(prod.previous_price)}</h4>
                                )}
                                <h4>Oferta: {currency(prod.offer_price)}</h4>
                                <h4>Autor: {prod.user.name}</h4>
                                {/*prod.price_on_offer && (
                                  <h4 className="text-primary text-center text-sm-center text-md-left text-lg-left">
                                    {currency(
                                      discountIsValid(prod.discount, prod.date_expiration)
                                        ? prod.offer_price
                                        : prod.previous_price
                                    )}

                                    {discountIsValid(prod.discount, prod.date_expiration) ? (
                                      <span
                                        className="text-muted ml-2"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {prod.discount}% de desconto até {isoDateToDefault(prod.date_expiration)}
                                      </span>
                                    ) : null}
                                  </h4>
                                )*/}
                                {/*prod.price_on_offer && (
                                  <div>
                                    Preço em Oferta:
                                    <span className="text-primary">
                                      {" "}
                                      {currency(prod.offer_price)} 
                                    </span>
                                  </div>
                                )*/}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                </Row>

              </>
            )}
          </div>
        </div>
      </Container>
      <ModalAddProduct
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          refresh();
        }}
      />
      <ModalEditProduct
        show={modalShowEdit}
        onHide={() => {
          setModalShowEdit(false);
          setSelectedProduct(null);
          refresh();
        }}
      />
    </>
  );
}
