import { Link } from "react-router-dom";
import Logo from "./../../assets/img/logo-horizontal.png";
import useAuth from "../../contexts/auth";
import { Navbar, Nav, NavDropdown, Button, Modal, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { FaUserCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import AddProductForm from "../AddProductForm";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import "./style.scss";
import useCategories from "../../hooks/useCategories";

import { useParams, useHistory } from "react-router-dom";
export default function NavBar() {
  const { user, logout } = useAuth();
  const { width } = useWindowSize();
  const [toggled, setToggled] = useState(false);
  const closeSidebar = () => setToggled(false);
  const [showModal, setShowModal] = useState(false);
  const categories = useCategories();
  const handleOpenModal = () => setShowModal(true);
  const [lastCategory, setLastCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000); // Aguarda 1 segundo antes de recarregar a página
  };

  function ModalAddProduct(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-primary">Adicionar Produto</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProductForm onSubmit={props.onHide} />
        </Modal.Body>
      </Modal>
    );
  }
  const renderUnlogged = () => {
    return (
      <Nav className="ml-auto">
        {/*
        <Nav.Link as={Link} to="/cadastro">
          Cadastre-se
        </Nav.Link>*/}
        <Nav.Link as={Link} to="/entrar">
          Entrar
        </Nav.Link>
      </Nav>
    );
  };

  const renderEntrepreneurSidebar = () => {
    return (
      <SubMenu title="Painel das Ofertas do Postador" defaultOpen>


        <MenuItem>
          <Link to="/poster/produtos" onClick={closeSidebar}>
            Meus Produtos
          </Link>
        </MenuItem>

      </SubMenu>
    );
  };

  const renderAdminSidebar = () => {
    return (
      <SubMenu title="Painel do Admin"  >
        <MenuItem >
          <Link to="/admin/dashboard" onClick={closeSidebar}>
            Dashboard
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/usuarios" onClick={closeSidebar}>
            Usuários
          </Link>
        </MenuItem>

        <MenuItem>
          <Link to="/admin/localidades" onClick={closeSidebar}>
            Localidades
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/categorias" onClick={closeSidebar}>
            Categorias
          </Link>
        </MenuItem>

        <MenuItem>
          <Link to="/admin/parceiros" onClick={closeSidebar}>
            Parceiros
          </Link>
        </MenuItem>

        <MenuItem>
          <Link to="/admin/tipos-categorias" onClick={closeSidebar}>
            Tipos de Categorias
          </Link>
        </MenuItem>

        <MenuItem>
          <Link to="/admin/products" onClick={closeSidebar}>
            Todos os Produtos
          </Link>
        </MenuItem>

        <MenuItem>
          <Link to="/admin/banner" onClick={closeSidebar}>
            Todos os Banners
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/ribbon-badges" onClick={closeSidebar}>
            Todos os Emblemas
          </Link>
        </MenuItem>
      </SubMenu>
    );
  };

  const renderUser = () => {
    return (
      <NavDropdown
        id="basic-nav-dropdown"
        title={
          <span className="align-self-center">
            <FaUserCircle className="text-secondary" size={"22"} />
            {width > 450 && <span className="ml-2">{user.name}</span>}
          </span>
        }
        alignRight
      >
        <NavDropdown.Item to="/conta" as={Link} onClick={closeSidebar}>
          Minha Conta
        </NavDropdown.Item>
        {user.type !== "admin" && (
          <>

          </>
        )}
        <NavDropdown.Item onClick={logout}>Sair</NavDropdown.Item>
      </NavDropdown>
    );
  };
  const CategoryModal = ({ categories, show, handleClose }) => {
    const navigate = useHistory(); // Se estiver usando React Router v6
    const resetSelection = () => {
      setLastCategory(null);
      setSelectedSubCategory(null);
      navigate.push('/');
      handleClose();
    };
    const handleCategorySelect = (id, isSubCategory, path) => {
      handleClose(); // Fechar o modal
      navigate.push(path);  // Ajuste para v6

      if (isSubCategory) {
        setSelectedSubCategory(id);
        setLastCategory(null);  // Garantir que não há conflito de estados
      } else {
        setLastCategory(id);
        setSelectedSubCategory(null);  // Garantir que não há conflito de estados
      }
    };

    return (
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="category-modal-title">
        <Modal.Header closeButton >
          <Modal.Title id="category-modal-title" style={{ cursor: 'pointer' }} onClick={resetSelection}>Categorias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {categories.map(category => (
              <Col key={category.id} md={6} lg={4}>
                <div className="category-section" style={{ cursor: 'pointer' }} onClick={() => handleCategorySelect(category.id, false, `/categoria/${category.id}`)}>
                  <h5>{category.name}</h5>
                  <ul>
                    {category.category_types && category.category_types.map(subCat => (
                      <li key={subCat.id} style={{ cursor: 'pointer' }} onClick={(e) => {
                        e.stopPropagation();
                        handleCategorySelect(subCat.id, true, `/subCategory/${subCat.id}`);
                      }}>
                        {subCat.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    );
  };
  const NavbarWithDropdown = ({ categories }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
      <Nav className="">
        <Nav.Link onClick={handleOpenModal}>Categorias</Nav.Link>
        <CategoryModal categories={categories} show={showModal} handleClose={handleCloseModal} />
      </Nav>
    );
  };

  const MobileNavbarWithDropdown = ({ categories }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
      <MenuItem onClick={handleOpenModal}>
        Categorias

        <CategoryModal categories={categories} show={showModal} handleClose={handleCloseModal} />



      </MenuItem>
    );
  };


  return (
    <>
      <div style={{ marginTop: '55px' }}>
        <Navbar fixed="top" bg="primary" variant="dark">
          <Navbar.Brand as={Link} to="/" onClick={closeSidebar}>
            <img
              src={Logo}
              alt="Logo"
              width="85px"

              className="mx-2 d-flex align-self-center"
            />
          </Navbar.Brand>
          {width > 992 && (
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/sobre" onClick={closeSidebar}>
                Grupo de Ofertas
              </Nav.Link>
              <Nav.Link as={Link} to="/" onClick={closeSidebar}>
                Melhores Ofertas
              </Nav.Link>
              <NavbarWithDropdown categories={categories} />
              <Nav.Link as={Link} to="/parceiros" onClick={closeSidebar}>
                Parceiros
              </Nav.Link>
              {user && user.type !== "client" && (
                <Nav.Link
                  as={Link}
                  to={user.type === "admin" ? "/admin" : "/poster"}
                >
                  Painel
                </Nav.Link>
              )}
              {user && user.type !== "client" && (
                <Nav.Link onClick={handleOpenModal}>Adicionar</Nav.Link>
              )}
            </Nav>

          )}
          {width < 992 && (
            <Button
              size="sm"
              className="ml-auto"
              onClick={() => setToggled((state) => !state)}
            >
              <GiHamburgerMenu />
            </Button>
          )}
          {user && <Nav xs="auto">{renderUser(user.type, width)}</Nav>}
          {!user && width > 992 && <Nav xs="auto">{renderUnlogged()}</Nav>}
          {/* <Nav xs="auto" className="ml-auto">
          {user && renderUser(user.type, width)}
          {!user && width > 992 && renderUnlogged()}
        </Nav> */}
        </Navbar>
        {width < 992 && (
          <ProSidebar

            breakPoint="lg"
            toggled={toggled}
            onToggle={(value) => {
              setToggled(value);
            }}
          >
            <SidebarContent style={{ background: "#000" }}>
              <Menu >
                <MenuItem>
                  <Link to="/sobre" onClick={closeSidebar}>
                    Grupo de Ofertas
                  </Link>

                </MenuItem>
                <MenuItem>

                  <Link to="/" onClick={closeSidebar}>
                    Melhores Ofertas
                  </Link>
                </MenuItem>

                <MenuItem>
                  <Link to="/parceiros" onClick={closeSidebar}>
                    Parceiros
                  </Link>


                </MenuItem>


                <MobileNavbarWithDropdown categories={categories} />



                {user && user.type !== "client" && (

                  <MenuItem onClick={handleOpenModal}>Adicionar</MenuItem>

                )}
                {/* {user && user.type !== "admin" && (
                <MenuItem>
                    <Link to="/compras">
                      Compras
                    </Link>
                </MenuItem>
              )} */}
                {user &&
                  user.type === "poster" &&
                  renderEntrepreneurSidebar()}
                {user && user.type === "admin" && renderAdminSidebar()}
                {!user /*&& (
                <MenuItem>
                  <Link to="/cadastro" onClick={closeSidebar}>
                    Cadastre-se
                  </Link>
                </MenuItem>
              )*/}
                {!user && (
                  <MenuItem>
                    <Link to="/entrar" onClick={closeSidebar}>
                      Entrar
                    </Link>
                  </MenuItem>
                )}
              </Menu>
            </SidebarContent>
          </ProSidebar>
        )}
      </div>
      <ModalAddProduct
        show={showModal}
        onHide={handleCloseModal}
      />
    </>
  );
}
