import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title="Política de Privacidade" />
      <Container className="h-100 py-5 px-5">
        <h3 className="h3 text-primary text-center font-weight-bold">
          Política de Privacidade do Caçadores de Ofertas
        </h3>
        <p className="pt-4 text-justify">
          {" "}
          Agradecemos por escolher o <b>Caçadores de Ofertas</b> Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e protegemos as informações pessoais dos usuários deste aplicativo. Ao utilizar o aplicativo, você concorda com as práticas descritas nesta política.
        </p>


        <ol className="mx-3 pt-2">
          <li className="h5 text-primary py-1">
            {" "}
            Informações Coletadas{" "}
          </li>
          <ul className="py-2 mx-3">
            <li className="pt-1 text-justify"> 1.1. Informações de Registro: Ao criar uma conta no aplicativo, coletamos seu nome, endereço de e-mail e senha. </li>
            <li className="pt-1 text-justify"> 1.2. Informações de Perfil: O usuário pode optar por fornecer informações adicionais em seu perfil, como preferências de compra, histórico de compras e outras informações relevantes. </li>
            <li className="pt-1 text-justify"> 1.3. Informações de Navegação: Coletamos automaticamente informações sobre o dispositivo utilizado, o sistema operacional, o endereço IP, a localização aproximada e as páginas visitadas dentro do aplicativo.</li>
            <li className="pt-1 text-justify">1.4. Cookies e Tecnologias Semelhantes: Utilizamos cookies e outras tecnologias semelhantes para melhorar a experiência do usuário, personalizar conteúdos e anúncios, além de analisar padrões de uso. </li>
          </ul>
          <li className="h5 text-primary py-1">
            {" "}
            Uso das Informações{" "}
          </li>
          <ul className="py-2 mx-3">
            <li className="pt-1 text-justify"> 2.1. Personalização: Utilizamos as informações coletadas para personalizar a experiência do usuário, fornecendo ofertas e promoções relevantes</li>
            <li className="pt-1 text-justify"> 2.2. Comunicações: Podemos enviar e-mails ou notificações push para fornecer informações sobre ofertas, atualizações do aplicativo ou outros comunicados relevantes. </li>
            <li className="pt-1 text-justify"> 2.3. Análises e Melhorias: Analisamos as informações coletadas para entender padrões de uso, otimizar o desempenho do aplicativo e desenvolver novos recursos.</li>
          </ul>
          <li className="h5 text-primary py-1">
            {" "}
            Compartilhamento de Informações{" "}
          </li>
          <ul className="py-2 mx-3">
            <li className="pt-1 text-justify"> 3.1. Parceiros Comerciais: Podemos compartilhar informações com parceiros comerciais para oferecer ofertas personalizadas e melhorar a experiência do usuário.</li>
            <li className="pt-1 text-justify"> 3.2. Requisitos Legais: Reservamo-nos o direito de divulgar informações quando exigido por lei ou para proteger nossos direitos legais. </li>
          </ul>
          <li className="h5 text-primary py-1">Segurança </li>
          <p className="py-2 text-justify">
            {" "}
            Implementamos medidas de segurança para proteger as informações dos usuários contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.{" "}
          </p>
          <li className="h5 text-primary py-1">
            {" "}
            Menores de Idade{" "}
          </li>
          <p className="py-2 text-justify">
            {" "}
            O aplicativo não é destinado a menores de 13 anos. Não coletamos intencionalmente informações pessoais de crianças.{" "}
          </p>

          <li className="h5 text-primary py-1">
            {" "}
            Alterações nesta Política{" "}
          </li>
          <p className="pt-2 text-justify">
            {" "}
            Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente. As alterações serão refletidas na data de "Última Atualização" acima.{" "}
          </p>


          <li className="h5 text-primary py-1">
            {" "}
            Contato{" "}
          </li>
          <p className="pt-2 text-justify">
            {" "}
            Se tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco por contato@cacadoresdeofertas.com{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Ao continuar a utilizar o aplicativo "Caçadores de Ofertas", você concorda com os termos desta Política de Privacidade.{" "}
          </p>

        </ol>
        <p className="text-right"> Data de publicação: 06/04/2024 </p>
      </Container>
      <Footer />
    </>
  );
}
