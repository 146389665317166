import { useState } from "react";
import { useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import useAuth from "../../../contexts/auth";
import usePaymentsMethod from "../../../hooks/usePaymentsMethod";
import { deleteEntrepreneur } from "../../../services/api";
import { getStates } from "../../../services/address";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ConfirmDeleteAccountModal from "../ConfirmDeleteAccountModal";
import UpdateEntrepreneurForm from "../../../components/UpdateEntrepreneurForm";
import useNotification from "../../../contexts/notification";

export default function AccountEntrepreneur() {
  const [states, setStates] = useState([]);
  const { user, logout } = useAuth();
  const paymentsMethod = usePaymentsMethod();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { setMessage } = useNotification();

  useEffect(() => {
    getStates().then((data) => {
      setStates(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <UpdateEntrepreneurForm
        entrepreneurId={user.id}
        states={states}
        paymentsMethod={paymentsMethod}
        onLoaded={() => {
          setShowDeleteBtn(true);
        }}
      />
      {showDeleteBtn && (
        <Alert variant="danger" className="mt-4">
          <Alert.Heading style={{ fontSize: "1.2em", fontWeight: "bold" }}>Você tem certeza?</Alert.Heading>
          <p>Esta ação resultará na exclusão da sua conta na plataforma Caçadores de ofertas, você não poderá mais recuperar seus dados.</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(true)}
            >
              Excluir conta
            </Button>
          </div>
        </Alert>
      )}
      <ConfirmDeleteAccountModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={async () => {
          await deleteEntrepreneur(user);
          setMessage({
            error: true,
            content: "Empreendedor deletado com sucesso",
          })
          logout(true);
        }}
      />
    </>
  );
}
