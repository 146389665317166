import { useState } from "react";
import { useFormik } from "formik";
import { Col, Form, Row, Button } from "react-bootstrap";
import { SignupEntrepreneurSchema } from "../../utils/validation_schemas";
import { createSignupEntrepreneurFormData } from "../../utils/payloads";


import InputMask from "react-input-mask";
import Adresss from "../Adresss";

export default function SignupEntrepreneurForm({ onSubmit }) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      type: "poster",
      name: "",
     
      telephone: "",
      email: "",
      city: "",
      state: "",
      password: "",  
      show_password: false,
    },
    validationSchema: SignupEntrepreneurSchema,
    onSubmit: async (values) => {
      const entrepreneur = { ...values };
      onSubmit(createSignupEntrepreneurFormData(entrepreneur)).then(() => {
        setLoading(false);
      });
    },

  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do Postador</h6>
      <Form.Group className="mt-1 mb-4" controlId="formEntData">
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Form.Label>Nome Completo *</Form.Label>
            <Form.Control
              className="my-1"
              type=""
              name="name"
              placeholder="Seu Nome completo"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={
                formik.touched.name &&
                !formik.errors.name
              }
              isInvalid={
                formik.errors.name &&
                formik.touched.name
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>

         
          <Col xl={6} md={6} sm={12}>
            <Form.Label>Telefone *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="telephone"
              placeholder="Telefone"
              as={InputMask}
              mask="(99)99999-9999"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={
                formik.errors.telephone && formik.touched.telephone
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Form.Label>Email *</Form.Label>
            <Form.Control
              className="my-1"
              type="email"
              name="email"
              placeholder="Seu email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={
                formik.errors.email && formik.touched.email ? true : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
          <Col lg={6}>
          <Form.Label>Nova senha</Form.Label>
          <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="password"
            placeholder="Nova senha"
            onChange={formik.handleChange}
            value={formik.values.password}
            isValid={formik.touched.password && !formik.errors.password}
            isInvalid={formik.errors.password}
          />
          <Form.Text className="text-muted">
            A senha deve ter no mínimo 8 caracteres entre números e letras.
          </Form.Text>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
          <Form.Check
            id="show-password-3"
            name="show_password"
            className="mb-2"
            type="switch"
            label="Mostrar senha"
            onChange={formik.handleChange}
            checked={formik.values.show_password}
            custom
          />
        </Col>
          
        </Row>
      </Form.Group>
      
      <Form.Group>
        <Adresss formik={formik} />
        <Button
          className="my-5"
          variant="primary"
          type="submit"
          disabled={loading}
        >
          Criar conta
        </Button>
        <p style={{ color: "red" }}>*Obrigatório</p>
      </Form.Group>
    </Form>
  );
}
