import { Line,Bar } from "react-chartjs-2";
import { getDashboardAdmin } from "../../services/api";
import { useEffect, useState } from "react";
import useNotification from "../../contexts/notification";
import LoadingIndicator from "../LoadingIndicator";

export default function GraficoAdmin({ year, month }) {
  const [clientes, setClients] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationsOpen, setNotificationsOpen] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setMessage } = useNotification();
  
  useEffect(() => { 
    getDashboardAdmin(month, year)
      .then((data) => {
        setClients(data.clientsCreate );
        setNotifications(data.notificationsCreate );
        setNotificationsOpen(data.notificationsOpen);
        setProducts(data.products );
        setLoading(false);
      })
      .catch((err) => {
        setMessage({
          error: true,
          content: "Ocorreu um erro, por favor tente novamente.",
        });
        setLoading(false);
      });
  }, [year, month, setMessage]);

  function mergeData(storeData, earningsData, notificationsOpen, products) {
    const merged = {};

    for (const item of storeData) {
      const date = item.month;
      merged[date] = {
        month: date,
        qtdCreateStore: item.qtdCreateStore,
        totalEarnings: 0,
      };
    }

    for (const item of earningsData) {
      const date = item.month;
      if (!merged[date]) {
        merged[date] = {
          month: date,
          qtdCreateStore: 0,
          totalEarnings: Number(item.totalEarnings),
        };
      } else {
        merged[date].totalEarnings = Number(item.totalEarnings);
      }
    }
    for (const item of notificationsOpen) {
      const date = item.month;
      if (!merged[date]) {
        merged[date] = {
          month: date,
          qtdCreateStore: 0,
          totalOpen: Number(item.totalOpen),
        };
      } else {
        merged[date].totalOpen = Number(item.totalOpen);
      }
    }

    for (const item of products) {
      const date = item.month;
      if (!merged[date]) {
        merged[date] = {
          month: date,
          qtdCreateStore: 0,
          totalProducts: Number(item.totalProducts),
        };
      } else {
        merged[date].totalProducts = Number(item.totalProducts);
      }
    }

    const sortedData = Object.values(merged).sort((a, b) => new Date(a.month) - new Date(b.month));


    return sortedData;
  }

  const mergedData = mergeData(clientes, notifications, notificationsOpen,products);

  const filteredData = year && month ? mergedData.filter((item) => item.month.includes(month) && item.month.includes(year)) : mergedData;

  return (
    <>
      {loading && <LoadingIndicator />}
      {!loading && (
        <Bar
          data={{
            labels: filteredData.map((item) => item.month),
            datasets: [
              {
                label: "Quantidade de clientes cadastradas",
                data: filteredData.map((item) => item.qtdCreateStore),
                backgroundColor: "rgba(255, 206, 86, 0.2)",  // Amarelo
                borderColor: "rgba(255, 206, 86, 1)",
                borderWidth: 5,
              },
              {
                label: "Quantidade de notificações enviadas",
                data: filteredData.map((item) => item.totalEarnings),
                backgroundColor: "rgba(75, 192, 192, 0.2)",  // Verde-água
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 5,
              },
              {
                label: "Quantidade de notificações Abertas",
                data: filteredData.map((item) => item.totalOpen),
                backgroundColor: "rgba(153, 102, 255, 0.2)",  // Roxo
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 5,
              },
              {
                label: "Quantidade de Produtos",
                data: filteredData.map((item) => item.totalProducts),
                backgroundColor: "rgba(255, 159, 64, 0.2)",  // Laranja
                borderColor: "rgba(255, 159, 64, 1)",
                borderWidth: 5,
              },
            ],
            
          }}
          height={400}
          options={
            {
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      var label = context.dataset.label || "";
                      if (label) {
                        label += ": ";
                      }
                      label += context.parsed.y.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                      return label;
                    },
                  },
                },
              },
            }
            
        
        }
        />
      )}
    </>
  );
}
