import { createContext, useContext } from "react";

export const NotificationContext = createContext({});

export default function useNotification() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useLocation must be used within an NotificationProvider.");
    }

    return context;
}