import RedirectContainer from "../../../components/RedirectContainer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  InputGroup,
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { useState, useEffect } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { TiInputChecked } from "react-icons/ti";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {
  paymentMethod,
  getPaymentsMethod,
  deletePayment,
  updatePaymentMethod,
  getPaymentMethod,
  searchPayment,
} from "../../../services/api";
import useNotification from "../../../contexts/notification";

function UpdatePaymentForm({ paymentId, onDone }) {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
  });
  const { setMessage } = useNotification();

  useEffect(() => {
    getPaymentMethod(paymentId).then(
      (data) => {
        const { payment } = data;
        setInitialValues({
          name: payment[0].name,
          id: payment[0].id,
        });
        setLoading(false);
      },
      () =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
    );
  }, [paymentId, setMessage]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const payment = { ...values };
          await updatePaymentMethod(payment);
          onDone?.();
          setMessage({
            error: false,
            content: "Método de pagamento atualizado com sucesso.",
          });
        } catch (err) {
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          });
        }
      }}
    >
      {({ values }) => (
        <Form as={FormikForm}>
          <Row className="mb-3">
            <Col lg={12}>
              <Form.Control
                as={Field}
                autoFocus
                className="my-1"
                type="text"
                name="name"
                placeholder="Nome do Pagamento"
                required="Campo necessário"
                value={values.name}
              />
            </Col>
          </Row>
          <Button className="d-flex align-items-center" type="submit">
            ATUALIZAR PAGAMENTO
            <TiInputChecked style={{ fontSize: "1.8em" }} />
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default function Payments() {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const { setMessage } = useNotification();

  const refresh = () => {
    const error = {
      error: true,
      content: "Erro ao recarregar. Tente novamente mais tarde",
    };

    if (query) {
      searchPayment(query).then(
        (data) => {
          setData(data.paymentsMethods);
        },
        () => setMessage(error)
      );
    } else {
      getPaymentsMethod().then(
        (data) => {
          setData(data.paymentsMethods);
        },
        () => setMessage(error)
      );
    }
  };

  function handleQueryPayment(e) {
    e.preventDefault();
    searchPayment(query).then(
      (data) => {
        setData(data.paymentsMethods);
      },
      
    );
  }

  useEffect(() => {
    if (query.length === 0) {
      getPaymentsMethod().then(
        (response) => {
          setData(response.paymentsMethods);
          setIsLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    }
  }, [query, setMessage]);

  useEffect(() => {
    if (selectedPayment) {
      setShowUpdatePaymentModal(true);
    }
  }, [selectedPayment]);

  function AddPaymentMethodModal(props) {
    const [loading, setLoading] = useState(false);

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-primary">Adicionar Pagamento</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ name: "" }}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                const payment = { ...values };
                await paymentMethod(payment);
                setLoading(false);
                setMessage({
                  error: false,
                  content: "Metodo de pagamento cadastrado com sucesso",
                });
                props.onHide();
              } catch (err) {
                setLoading(false);
                setMessage({
                  error: true,
                  content: "Um erro ocorreu. Tente novamente mais tarde",
                });
              }
            }}
          >
            {({ values }) => (
              <Form as={FormikForm}>
                <Row className="mb-3">
                  <Col lg={12}>
                    <Form.Control
                      as={Field}
                      autoFocus
                      className="py-1"
                      type="text"
                      name="name"
                      placeholder="Nome do Pagamento"
                      required="Campo necessário"
                      value={values.name}
                    />
                  </Col>
                </Row>
                <Button
                  className="d-flex align-items-center"
                  type="submit"
                  disabled={loading}
                >
                  SALVAR PAGAMENTO
                  <TiInputChecked style={{ fontSize: "1.8em" }} />
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  function UpdatePaymentMethodModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-primary">Adicionar Pagamento</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdatePaymentForm
            paymentId={selectedPayment}
            onDone={props.onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <RedirectContainer role="admin">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin />
            <div className="content-panel">
              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <>
                  <div className="py-3">
                    <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                      <FaMoneyBillWave className="mr-2" />
                      Formas de pagamento
                    </h5>
                  </div>

                  <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                    <div>
                      <Form >
                        <Form.Group className="d-flex" controlId="">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="Buscar método"
                              required
                              minLength="3"
                              onKeyUp={handleQueryPayment}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </div>

                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center align-items-sm-center align-items-md-start align-items-lg-start">
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="mr-md-3 mr-lg-3 mr-0 mr-sm-0 mb-2 mb-sm-2 mb-md-0 mb-lg-0"
                        >
                          ADICIONAR PAGAMENTO
                        </Button>

                        <AddPaymentMethodModal
                          show={modalShow}
                          onHide={() => {
                            setModalShow(false);
                            refresh();
                          }}
                        />
                        <UpdatePaymentMethodModal
                          show={showUpdatePaymentModal}
                          onHide={() => {
                            setShowUpdatePaymentModal(false);
                            setSelectedPayment(null);
                            refresh();
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <Row>
                    <Col lg={12}>
                      <Table striped bordered responsive size="sm">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th className="w-25">Ações</th>
                          </tr>
                        </thead>
                        {data.length === 0 && (
                          <h5 className="text-muted text-center my-5">
                            Sem resultados
                          </h5>
                        )}
                        <tbody>
                          {data.map((pay) => (
                            <tr key={pay.id}>
                              <td>{pay.name}</td>
                              <td className="d-flex">
                                <Button
                                  variant="warning"
                                  className="text-white d-flex align-items-center mr-3"
                                  size="sm"
                                  onClick={() => {
                                    setSelectedPayment(pay.id);
                                  }}
                                >
                                  <MdEdit style={{ fontSize: "1.4em" }} />
                                </Button>

                                <Button
                                  size="sm"
                                  onClick={() => {
                                    let conf = window.confirm("Tem certeza que deseja deletar este método de pagamento?");
                                    if(conf) {
                                      deletePayment(pay).then(
                                        () => setMessage({
                                          error: false,
                                          content: "Metodo de pagamento deletado com sucesso."
                                        }), () => setMessage({
                                          error: true,
                                          content: "Ocorreu um erro, por favor tente novamente."
                                        })
                                      )
                                      refresh();
                                    }
                                  }}
                                  variant="danger"
                                  className="text-white d-flex align-items-center"
                                >
                                  <MdDelete style={{ fontSize: "1.4em" }} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </RedirectContainer>
    </>
  );
}
