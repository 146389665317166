import { useState } from "react";
import { Button, Alert } from "react-bootstrap";

import useAuth from "../../../contexts/auth";
import ConfirmDeleteAccountModal from "../ConfirmDeleteAccountModal";
import UpdateClientForm from "../../../components/UpdateClientForm";
import { deleteUser } from "../../../services/api";
import useNotification from "../../../contexts/notification";

export default function AccountClient() {
  const { user, logout } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const { setMessage } = useNotification();

  return (
    <>
      <UpdateClientForm
        // states={states}
        clientId={user.id}
        onLoaded={() => {
          setShowDeleteBtn(true);
        }}
      />
      {showDeleteBtn && (
        <Alert variant="danger" className="mt-4">
          <Alert.Heading style={{ fontSize: "1.2em", fontWeight: "bold" }}>Você tem certeza?</Alert.Heading>
          <p>Esta ação resultará na exclusão da sua conta na plataforma Caçadores de ofertas, você não poderá mais recuperar seus dados.</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(true)}
            >
              Excluir conta
            </Button>
          </div>
        </Alert>
      )}
      <ConfirmDeleteAccountModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={async () => {
          await deleteUser(user);
          setMessage({
            error: true,
            content: "Cliente deletado com sucesso",
          })
          logout(true);
        }}
      />
    </>
  );
};
