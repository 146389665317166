import { Spinner } from "react-bootstrap";
import "./styles.scss";

export default function LoadingIndicator() {
  return (
    <div className="loading-indicator d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status" variant="primary" size="lg" />
    </div>
  );
}
