import { Container, Button, Jumbotron, ListGroup, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import brand from "../../assets/img/logo-vertical-colored.svg";
import Brand from "../../assets/img/fundo1.jpg";
import products from "./img/Products-bro.svg";
import baker from "./img/Baker-bro.svg";
import credit from "./img/Credit-bro.svg";
import barcode from "./img/Barcode-bro.svg";
import marketing from "./img/Marketing-bro.svg";
import paid from "./img/Paid-bro.svg";
import analysis from "./img/Analysis-bro.svg";
import people from "./img/People-bro.svg";
import fruitshop from "./img/FruitShop-bro.svg";
import { MdEdit, MdWhatsapp, MdCheck } from "react-icons/md";
import { FaTelegramPlane, FaBolt, FaCut, FaLock, FaHandshake, FaQuestionCircle, FaHistory, FaShoppingBag } from 'react-icons/fa';
import "./styles.scss";
import { Helmet } from "react-helmet";
import qr from "../../assets/img/QR Code - Grupos de Ofertas.png"
export default function Home() {
  return (
    <>
      <Helmet title="Jersu Indica: Home" />
      <div className="banner-cacadores">
        <Container>
          <Row className="align-items-center">
            <Col lg={4} className="text-right">
              <img src={Brand} alt="Jersu" className="brand-logo" />
            </Col>
            <Col lg={5}>
              <h1>Participe dos meus grupos de oferta no WhatsApp e Telegram</h1>
              <p>Criamos um canal de divulgação no WhatsApp e no Telegram para postar produtos em promoção! Inclusive os que mostramos no canal, então aproveitem! divulgue esta página para seus amigos e vamos economizar e comprar nos locais mais confiáveis!

              </p>

              <div className="banner-buttons">
                <a href="https://t.me/cuponsjersu" rel="noopener noreferrer" className="promo-button-link">
                  <Button variant="outline-light" className="btn-block btn-telegram">
                    <FaTelegramPlane /> GRUPO NO TELEGRAM
                  </Button>
                </a>
                <br />
                <a href="https://chat.whatsapp.com/L5L4mDl2X7tA3Z1cv23mD7" rel="noopener noreferrer" className="promo-button-link">
                  <Button variant="success" className=" btn-whatsapp btn-block">
                    <MdWhatsapp /> GRUPOS NO WHATSAPP
                  </Button>
                </a>
              </div>
            </Col>


          </Row>
        </Container>


        <Container className="mb-5">
          <Row>
            <Col xs={12} className="text-center mt-5">
              <h2 className="mb-3" style={{ color: "#7EDCDC", fontWeight: "bold" }}>O que você vai receber?</h2>
              <Row className="beneficios-section text-center">
                <Col md={3} className="beneficio-item">
                  <div className="beneficio-icon-container">
                    <FaShoppingBag className="beneficio-icon" />
                  </div>
                  <div style={{ fontFamily: "Arial, sans-serif", color: "#B0BEC5", textAlign: "center" }}>
                    Promoções Diariamente.
                  </div>


                </Col>
                <Col md={3} className="beneficio-item-2">
                  <div className="beneficio-icon-container-2">
                    <FaHandshake className="beneficio-icon-2" />
                  </div>



                  <div style={{ fontFamily: "Arial, sans-serif", color: "#B0BEC5", textAlign: "center" }}>Apoio na hora da compra.</div>

                </Col>
                <Col md={3} className="beneficio-item">
                  <div className="beneficio-icon-container">
                    <FaQuestionCircle className="beneficio-icon" />
                  </div>
                  <div style={{ fontFamily: "Arial, sans-serif", color: "#B0BEC5", textAlign: "center" }} >Poderá tirar suas Dúvidas.​</div>

                </Col>
                <Col md={3} className="beneficio-item-2">
                  <div className="beneficio-icon-container-2">
                    <FaHistory className="beneficio-icon-2" />
                  </div>
                  <div style={{ fontFamily: "Arial, sans-serif", color: "#B0BEC5", textAlign: "center" }} >Histórico de preços (Apenas no Telegram).​</div>

                </Col>
              </Row>
            </Col>
            <Col xs={12} className="text-center mt-5">
              <h2 className="mb-3" style={{ color: "#7EDCDC", fontWeight: "bold" }}>Como funcionam os grupos?</h2>
              <Row className="my-3 align-items-center">
                <Col>
                  <div className="video-container">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/8PlKv7BB2R8"
                      title="Vídeo YouTube"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
                    </iframe>
                  </div>
                </Col>
              </Row>
            </Col>
            <br />
            <br />
            <Col lg={6}>
              <br />
              <br />
              <div className="banner-buttons">
                <a href="https://t.me/cuponsjersu" rel="noopener noreferrer" className="promo-button-link">
                  <Button variant="outline-light" className="btn-block btn-telegram">
                    <FaTelegramPlane /> GRUPO NO TELEGRAM
                  </Button>
                </a>
                <br />
                <a href="https://chat.whatsapp.com/I9Iov8ypjEqDlVK1E8QGVL" rel="noopener noreferrer" className="promo-button-link">
                  <Button variant="success" className=" btn-whatsapp btn-block">
                    <MdWhatsapp /> GRUPOS NO WHATSAPP
                  </Button>
                </a>
              </div>
            </Col>


          </Row>
          <hr className="hr-big" />
        </Container>

      </div>
      <Footer />
    </>
  );
}
