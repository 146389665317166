import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";
import { FaInstagram, FaWhatsapp, FaYoutube, FaTelegramPlane, FaFacebook } from "react-icons/fa";
import Brand from "../../assets/img/logo-horizontal.png";

export default function Footer() {
  return (
    <>
      <div className="main-footer bg-primary">
        <Container fluid>
          <Row className="pt-4 justify-content-center">
            <Col xs={6} md="auto" className="d-flex justify-content-center justify-content-md-start">
              <img src={Brand} alt="logo" height="48px" />
            </Col>
            <Col md="auto" style={{ color: 'white' }}>
              <h5 style={{ color: 'white' }}>Quem somos</h5>
              <p>Somos uma comunidade que nasceu com a missão de fazer você, consumidor brasileiro, economizar em suas compras!</p>
              <p>Compartilhamos diariamente produtos com as melhores promoções das principais lojas do Brasil.</p>
            </Col>
            <Col xs={6} md="auto">
              <h5 className="text-white">Menu</h5>
              <div><Link to="/entrar" className="text-md-left">Entrar</Link></div>
              <div><Link to="/sobre">Sobre</Link></div>


            </Col>


            <Col xs={6} md="auto" className="mt-md-2 order-md-1">
              <h5 className="text-white">Suporte</h5>
              <div className="suporte">
                <a href="mailto:@contatojersu@gmail.com">contatojersu@gmail.com</a>
              </div>
            </Col>

          </Row>
          <Row className="justify-content-center justify-content-md-end">
            <Col xs="auto">
              <a
                href="https://www.instagram.com/jeersu/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className="p-2 social-links" size="48" />
              </a>
            </Col>
            <Col xs="auto">
              <a
                href="https://chat.whatsapp.com/I9Iov8ypjEqDlVK1E8QGVL"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp className="p-2 social-links" size="48" />
              </a>
            </Col>
            <Col xs="auto">
              <a
                href="https://www.youtube.com/@Jersu"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="p-2 social-links" size="48" />
              </a>
            </Col>
            <Col xs="auto">
              <a
                href="https://t.me/cuponsjersu"
                target="_blank"
                rel="noreferrer"
              >
                <FaTelegramPlane className="p-2 social-links" size="48" />
              </a>
            </Col>
          </Row>
          <hr className="bg-white" />

          <Row className="justify-content-between">
            <Col xs="auto" className="text-white">
              {`© Jersu  | Políticas de privacidade ${new Date().getFullYear()}. Todos os direitos reservados.`}
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
}

