import RedirectContainer from "../../../components/RedirectContainer";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Table,
    Modal,
    InputGroup,
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { BsFillGridFill } from "react-icons/bs";
import { Formik, useFormik, Form as FormFormik, Field } from "formik";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { TiInputChecked } from "react-icons/ti";
import {
    getAllCategoriesTypes,
    getAllCategories,
    categoryType,
    deleteCategoryType,
    getCategoryType,
    updateCategoryType,
    searchCategoryType,
} from "../../../services/api";
import useNotification from "../../../contexts/notification";
import PaginationComponent from "../../../components/Pagination";
function UpdateCategoryForm({ categoryId, onDone }) {
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
      name: "",
      description:"",
      category_id: "",
    });
    const { setMessage } = useNotification();
    const [categories, setCategories] = useState([]);
  
    useEffect(() => {
      getAllCategories().then((data) => {
        const categoriesFiltered = data.filter((c) => c.name !== "Vale Presentes");
        setCategories(categoriesFiltered);
      });
    }, []);
  
    useEffect(() => {
      getCategoryType(categoryId).then(
        (data) => {
          
            const categoryTypeData = data.categories[0];; // Ajuste isso de acordo com a estrutura real da sua resposta
          
            
            setInitialValues({
            name: categoryTypeData.name,
            description:categories.description,
            category_id: categoryTypeData.category_id,
            id: categoryTypeData.id,
          });
          setLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    }, [categoryId, setMessage]);
  
    if (loading) {
      return <LoadingIndicator />;
    }
  
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await updateCategoryType(values);
            setMessage({
              error: false,
              content: "Categoria atualizada com sucesso",
            });
            onDone?.();
          } catch (err) {
            setMessage({
              error: true,
              content: "Um erro ocorreu. Tente novamente mais tarde",
            });
          }
          setSubmitting(false);
        }}
      >
        {formik => (
          <FormFormik>
            <Row className="mb-3">
              <Col lg={12}>
                <Form.Control
                  autoFocus
                  className="my-1"
                  type="text"
                  name="name"
                  placeholder="Nome do Tipo da Categoria"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  required
                />
              </Col>
              <Col xl={12} md={12} sm={12}>
            <Form.Label>Descrição *</Form.Label>
            <Form.Control
              className="my-1"
              as="textarea"
              rows={3}
              name="description"
              placeholder="Descrição da  categoria"
              onChange={formik.handleChange}
              value={formik.values.description}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={formik.errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Col>
              <Col xl={6} md={6} sm={6}>
                <Form.Label>Categoria *</Form.Label>
                <Form.Control
                  as="select"
                  name="category_id"
                  value={formik.values.category_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                >
                  <option>Selecione uma categoria</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Button className="d-flex align-items-center" type="submit">
              ATUALIZAR Tipo de Categoria
              <TiInputChecked style={{ fontSize: "1.8em" }} />
            </Button>
          </FormFormik>
        )}
      </Formik>
    );
  }
function AddCategoryModal(props) {
    const [loading, setLoading] = useState(false);
    const { setMessage } = useNotification();
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        getAllCategories().then((data) => {
          const categoriesFiltered = data.filter((c) => c.name !== "Vale Presentes");
          setCategories(categoriesFiltered);
        });
      }, []);
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            category_id: undefined,
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const categories = { ...values };
                await categoryType(categories);
                setLoading(false);
                setMessage({
                    error: false,
                    content: "Categoria cadastrado com sucesso",
                });
                props.onHide();
            } catch (err) {
                setLoading(false);
                setMessage({
                    error: true,
                    content: "Um erro ocorreu. Tente novamente mais tarde",
                });
            }
        },
    });

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="text-primary">Adicionar Tipo de  Categoria</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Row className="mb-3">
                        <Col lg={12}>
                            <Form.Control
                                autoFocus
                                className="py-1"
                                type="text"
                                name="name"
                                placeholder="Nome do Tipo da categoria"
                                required="Campo necessário"
                                onChange={formik.handleChange}

                            />
                        </Col>
                        <Col xl={12} md={12} sm={12}>
            <Form.Label>Descrição *</Form.Label>
            <Form.Control
              className="my-1"
              as="textarea"
              rows={3}
              name="description"
              placeholder="Descrição da  categoria"
              onChange={formik.handleChange}
              value={formik.values.description}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={formik.errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Col>
                        <Col xl={6} md={6} sm={6}>
            <Form.Label>Categoria *</Form.Label>
            <Form.Control
              as="select"
              name="category_id"
              isValid={formik.touched.category_id && !formik.errors.category_id}
              isInvalid={formik.errors.category_id}
              onChange={formik.handleChange}
              value={formik.values.category_id}
              autoComplete="off"
              disabled={formik.values.isPromotion === "1"}
              custom
            >
              <option>Selecione uma categoria</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Col>
                    </Row>
                    <Button
                        className="d-flex align-items-center"
                        type="submit"
                        disabled={loading}
                    >
                        SALVAR O TIPO DE CATEGORIA
                        <TiInputChecked style={{ fontSize: "1.8em" }} />
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default function Categories() {
    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [page, setPage] = useState(1);
    const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { setMessage } = useNotification();
    
    const refresh = () => {
        const error = {
            error: true,
            content: "Erro ao recarregar. Tente novamente mais tarde",
        };

        if (query) {
            searchCategoryType(query,page).then(
                (data) => {
                    setData(data.categories);
                },
                () => setMessage(error)
            );
        } else {
            getAllCategoriesTypes(page).then(
                (data) => {
                    setData(data);
                },
                () => setMessage(error)
            );
        }
    };

    function handleQueryCategory(e) {
        e.preventDefault();
        searchCategoryType(query,page).then((data) => {
            setData(data.categories || []);
        });
    }

    useEffect(() => {
        if (selectedCategory) {
            setShowUpdateCategoryModal(true);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (query.length === 0) {
            getAllCategoriesTypes(page).then(
                (response) => {
                    setData(response);
                    setIsLoading(false);
                },
                () =>
                    setMessage({
                        error: true,
                        content: "Um erro ocorreu. Tente novamente mais tarde",
                    })
            );
        }else{
            setPage(1)
        }
    }, [query,page, setMessage]);

    function UpdateCategoryModal(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="text-primary">Adicionar Tipo de Categoria</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateCategoryForm
                        categoryId={selectedCategory}
                        onDone={props.onHide}
                    />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <RedirectContainer role="admin">
                <Container fluid>
                    <div>
                        <SidebarPanelNavigation isAdmin />
                        <div className="content-panel">
                            {isLoading && <LoadingIndicator />}
                            {!isLoading && (
                                <>
                                    <div className="py-3">
                                        <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                                            <BsFillGridFill className="mr-2" />
                                            Tipos de Categoria
                                        </h5>
                                    </div>

                                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                                        <div>
                                            <Form >
                                                <Form.Group className="d-flex" controlId="">
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Buscar categoria"
                                                            onKeyUp={handleQueryCategory}
                                                            required
                                                            onChange={(e) => setQuery(e.target.value)}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form>
                                        </div>

                                        <div>
                                            <Button
                                                variant="primary"
                                                onClick={() => setShowAddCategoryModal(true)}
                                                className=" mr-md-3 mr-lg-3 mr-0 mr-sm-0 mb-2 mb-sm-2 mb-md-0 mb-lg-0"
                                            >
                                                <span className="mr-2">ADICIONAR TIPO DE CATEGORIA</span>
                                                <IoIosAddCircle style={{ fontSize: "1.6em" }} />
                                            </Button>

                                            <AddCategoryModal
                                                show={showAddCategoryModal}
                                                onHide={() => {
                                                    setShowAddCategoryModal(false);
                                                    refresh();
                                                }}
                                            />
                                            <UpdateCategoryModal
                                                show={showUpdateCategoryModal}
                                                onHide={() => {
                                                    setShowUpdateCategoryModal(false);
                                                    setSelectedCategory(null);
                                                    refresh();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <Row>
                                        <Col lg={12}>

                                            <Table striped bordered hover responsive size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo de Categoria</th>
                                                        <th>Descrição</th>
                                                        <th>Categoria</th>
                                                        <th className="w-25">Ações</th>
                                                    </tr>
                                                </thead>
                                                {data.length === 0 && (
                                                    <h5 className="text-muted text-center my-5">
                                                        Sem resultados
                                                    </h5>
                                                )}
                                                <tbody>
                                                    {data.map((categories) => (
                                                        <tr key={categories.id} className="w-25">
                                                            <td>{categories.name}</td>
                                                            <td>{categories.description}</td>
                                                            <td>{categories.categories.name}</td>
                                                            <td className="d-flex">
                                                                <Button
                                                                    variant="warning"
                                                                    size="sm"
                                                                    className="text-white d-flex align-items-center mr-3"
                                                                    onClick={() => {
                                                                        setSelectedCategory(categories.id);
                                                                    }}
                                                                >
                                                                    <MdEdit style={{ fontSize: "1.4em" }} />
                                                                </Button>

                                                                <Button
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        let conf = window.confirm("Esta ação deletará o cartão fidelidade.");
                                                                        if (conf) {
                                                                            deleteCategoryType(categories).then(
                                                                                () => setMessage({
                                                                                    error: false,
                                                                                    content: "Categoria deletada com sucesso."
                                                                                }), () => setMessage({
                                                                                    error: true,
                                                                                    content: "Ocorreu um erro, tente novamente mais tarde."
                                                                                })
                                                                            )
                                                                            refresh();
                                                                        }
                                                                    }
                                                                    }
                                                                    variant="danger"
                                                                    className="text-white d-flex align-items-center"
                                                                >
                                                                    <MdDelete style={{ fontSize: "1.4em" }} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                           
                                            {/* <div className="d-flex justify-content-end">
                        <Pagination>
                          <Pagination.First />
                          <Pagination.Prev />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Item>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item>
                          <Pagination.Next />
                          <Pagination.Last />
                        </Pagination>
                      </div> */}
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </RedirectContainer>
        </>
    );
}
