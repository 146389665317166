import "./styles.scss";
import { currency } from "../../utils/mappers";
import { Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FiGrid } from "react-icons/fi";
import "./styles.scss";
import { getImage } from "../../services/api";

export default function GiftVoucherCard({ giftvoucher }) {
  const history = useHistory();




  return (
    <div className="flex-container" onClick={() => history.push(`/loja/${giftvoucher.slug}`)}>
    <div className="image-container">
      <img src={giftvoucher.image_url} alt={giftvoucher.name} className="image" />
    </div>
    <div className="text-container">
      <h3 className="title">{giftvoucher.name}</h3>
      <p className="description">{giftvoucher.description}</p>
    </div>
  </div>
  );
}
