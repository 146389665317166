import "./styles.scss";
import { Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FaEnvelope, FaPeopleCarry, FaInstagram, FaUser } from "react-icons/fa";
import Footer from "../../components/Footer";
import { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet title="Jersu Indica: Contato" />
      <Container className="py-5">
        <h4 className="text-primary">Contato</h4>
        <Card className="mt-5">
          <Card.Header>
            <Card.Title>Responsável pelo sistema</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <FaUser className="mr-1" />
              <span> Intelsust</span>
            </div>
            <div>
              <FaInstagram className="mr-1" />
              <a
                className="social"
                href="https://www.instagram.com/intelsust/"
                target="_blank"
                rel="noreferrer"
              >
                @intelsust
              </a>
            </div>
            <div>
              <FaEnvelope className="mr-1" />
              <span>intelsust@gmail.com</span>
            </div>
          </Card.Body>
        </Card>
        <Card className="mt-5">
          <Card.Header>
            <Card.Title>Desenvolvedor</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <FaPeopleCarry className="mr-1" />
              <span>Gessé Fernandes Martins</span>
            </div>
            <div>
              <FaEnvelope className="mr-1" />
              <span>gesse-fm@hotmail.com</span>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
