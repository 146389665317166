import RedirectContainer from "../../../components/RedirectContainer";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Table,
    Modal,
    InputGroup,
    Image
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { BsFillGridFill } from "react-icons/bs";
import { Formik, useFormik, Form as FormFormik, Field } from "formik";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete, MdEdit,MdCheckCircle } from "react-icons/md";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { TiInputChecked } from "react-icons/ti";
import {
    getAllBanners,
    AddBanner,
    deleteBanner,
    getBanner,
    updateBanner,
    searchPartner,
    activeProdutor,
    desativar
} from "../../../services/api";
import useNotification from "../../../contexts/notification";

function UpdatePartnerForm({ categoryId, onDone }) {
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
        link: "",
      
        image: undefined,

    });
    const { setMessage } = useNotification();
    const [categories, setCategories] = useState([]);



    useEffect(() => {
        getBanner(categoryId).then(
            (data) => {
              
                const categoryTypeData = data.banner[0];
                setInitialValues({
                    link: categoryTypeData.link,
                  

                    id: categoryTypeData.id,
                });
                setLoading(false);
            },
            () =>
                setMessage({
                    error: true,
                    content: "Um erro ocorreu. Tente novamente mais tarde",
                })
        );
    }, [categoryId, setMessage]);

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
                const formData = new FormData();

    // Adiciona os valores do formulário ao formData.
   

    // Adiciona a imagem somente se ela for um arquivo.
    // A checagem "instanceof File" garante que estamos lidando com um arquivo.
    if (values.image instanceof File) {
        formData.append('image', values.image);
    }
                try {
                    if (values.image === undefined) {
                        delete values.image;
                      }
                      
                    await updateBanner(formData,values.id);
                    setMessage({
                        error: false,
                        content: "Categoria atualizada com sucesso",
                    });
                    onDone?.();
                } catch (err) {
                    setMessage({
                        error: true,
                        content: "Um erro ocorreu. Tente novamente mais tarde",
                    });
                }
                setSubmitting(false);
            }}
        >
            {formik => (
                <FormFormik>
                    <Row className="mb-3">
                    <Col xl={12} md={12} sm={12}>
            <Form.Label>Link do Banner</Form.Label>
            <Form.Control
              className="my-1"
              type="url"
              name="link"
              placeholder="http://exemplo-de-oferta.com"
              onChange={formik.handleChange}
              value={formik.values.link}
              isValid={formik.touched.link && !formik.errors.link}
              isInvalid={formik.errors.link}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.link}
            </Form.Control.Feedback>
          </Col>
                       
                        <Col xl={6} md={6} sm={6} className="mt-1">
                            <Form.Label>Imagem do Parceiro*</Form.Label>
                            <Form.File
                                id=""
                                accept="image/png, image/jpeg"
                                name="image"
                                lang="pt"
                                label={
                                    formik.values.image
                                        ? formik.values.image.name
                                        : "Escolher arquivo"
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("image", e.currentTarget.files[0])
                                }
                                isInvalid={formik.errors.image}
                                feedback={formik.errors.image}
                                custom
                            />
                        </Col>
                    </Row>
                    <Button className="d-flex align-items-center" type="submit">
                        ATUALIZAR BANNER
                        <TiInputChecked style={{ fontSize: "1.8em" }} />
                    </Button>
                </FormFormik>
            )}
        </Formik>
    );
}
function AddPartnerModal(props) {
    const [loading, setLoading] = useState(false);
    const { setMessage } = useNotification();
    const [categories, setCategories] = useState([]);

    const formik = useFormik({
        initialValues: {
           
            link: "",
            image: undefined,

        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                let formData = new FormData();

                // Adiciona os valores do formulário, exceto a imagem
                formData.append('link', values.link);

                // Adiciona a imagem se uma foi selecionada
                if (values.image) {
                    formData.append('image', values.image);
                }
                console.log(formData);
                await AddBanner(formData);
                setLoading(false);
                setMessage({
                    error: false,
                    content: "Parceiro cadastrado com sucesso",
                });
                props.onHide();
            } catch (err) {
                setLoading(false);
                setMessage({
                    error: true,
                    content: "Um erro ocorreu. Tente novamente mais tarde",
                });
            }
        },
    });

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className="text-primary">Adicionar Banner</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Row className="mb-3">
                        
                        <Col xl={12} md={12} sm={12}>
            <Form.Label>Link do Banner</Form.Label>
            <Form.Control
              className="my-1"
              type="url"
              name="link"
              placeholder="http://exemplo-de-oferta.com"
              onChange={formik.handleChange}
              value={formik.values.link}
              isValid={formik.touched.link && !formik.errors.link}
              isInvalid={formik.errors.link}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.link}
            </Form.Control.Feedback>
          </Col>
                        
                        <Col xl={6} md={6} sm={6} className="mt-1">
                            <Form.Label>Imagem do Parceiro*</Form.Label>
                            <Form.File
                                id=""
                                accept="image/png, image/jpeg"
                                name="image"
                                lang="pt"
                                label={
                                    formik.values.image
                                        ? formik.values.image.name
                                        : "Escolher arquivo"
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("image", e.currentTarget.files[0])
                                }
                                isInvalid={formik.errors.image}
                                feedback={formik.errors.image}
                                custom
                            />
                        </Col>

                    </Row>
                    <Button
                        className="d-flex align-items-center"
                        type="submit"
                        disabled={loading}
                    >
                        SALVAR O BANNER
                        <TiInputChecked style={{ fontSize: "1.8em" }} />
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default function Partners() {
    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

    const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { setMessage } = useNotification();

    const refresh = () => {
        const error = {
            error: true,
            content: "Erro ao recarregar. Tente novamente mais tarde",
        };

        if (query) {
            searchPartner(query).then(
                (data) => {
                    setData(data);
                },
                () => setMessage(error)
            );
        } else {
            getAllBanners().then(
                (data) => {
                    setData(data);
                },
                () => setMessage(error)
            );
        }
    };

   

    useEffect(() => {
        if (selectedCategory) {
            setShowUpdateCategoryModal(true);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (query.length === 0) {
            getAllBanners().then(
                (response) => {

                    setData(response);
                    setIsLoading(false);
                },
                () =>
                    setMessage({
                        error: true,
                        content: "Um erro ocorreu. Tente novamente mais tarde",
                    })
            );
        }
    }, [query, setMessage]);

    function UpdateCategoryModal(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="text-primary">Adicionar Parceiro</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdatePartnerForm
                        categoryId={selectedCategory}
                        onDone={props.onHide}
                    />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <RedirectContainer role="admin">
                <Container fluid>
                    <div>
                        <SidebarPanelNavigation isAdmin />
                        <div className="content-panel">
                            {isLoading && <LoadingIndicator />}
                            {!isLoading && (
                                <>
                                    <div className="py-3">
                                        <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                                            <BsFillGridFill className="mr-2" />
                                            BANNERS
                                        </h5>
                                    </div>

                                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                                        <div>
                                            
                                        </div>

                                        <div>
                                            <Button
                                                variant="primary"
                                                onClick={() => setShowAddCategoryModal(true)}
                                                className=" mr-md-3 mr-lg-3 mr-0 mr-sm-0 mb-2 mb-sm-2 mb-md-0 mb-lg-0"
                                            >
                                                <span className="mr-2">ADICIONAR BANNER</span>
                                                <IoIosAddCircle style={{ fontSize: "1.6em" }} />
                                            </Button>

                                            <AddPartnerModal
                                                show={showAddCategoryModal}
                                                onHide={() => {
                                                    setShowAddCategoryModal(false);
                                                    refresh();
                                                }}
                                            />
                                            <UpdateCategoryModal
                                                show={showUpdateCategoryModal}
                                                onHide={() => {
                                                    setShowUpdateCategoryModal(false);
                                                    setSelectedCategory(null);
                                                    refresh();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <Row>
                                        <Col lg={12}>

                                            <Table striped bordered hover responsive size="sm">
                                                <thead>
                                                    <tr>
                                                     
                                                        <th>Link</th>
                                                        <th>Imagem</th>
                                                        <th>Status</th>
                                                        <th className="w-25">Ações</th>
                                                    </tr>
                                                </thead>

                                                {data.length === 0 && (
                                                    <h5 className="text-muted text-center my-5">
                                                        Sem resultados
                                                    </h5>
                                                )}
                                                <tbody>
                                                    {data.map((categories) => (
                                                        <tr key={categories.id} className="w-25">
                                                            <td>{categories.link}</td>
                                                           

                                                            <td>
                                                                <Image
                                                                    src={(categories.image_url)}
                                                                    style={{ width: "100px" }}
                                                                />
                                                            </td>
                                                            <td>{categories.status=='0'?'Inativo':'Ativo'}</td>
                                                            <td className="d-flex">
                                                                <Button
                                                                    variant="warning"
                                                                    size="sm"
                                                                    className="text-white d-flex align-items-center mr-3"
                                                                    onClick={() => {
                                                                        setSelectedCategory(categories.id);
                                                                    }}
                                                                >
                                                                    <MdEdit style={{ fontSize: "1.4em" }} />
                                                                </Button>

                                                                <Button
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        let conf = window.confirm("Esta ação deletará o cartão fidelidade.");
                                                                        if (conf) {
                                                                            deleteBanner(categories).then(
                                                                                () => setMessage({
                                                                                    error: false,
                                                                                    content: "Categoria deletada com sucesso."
                                                                                }), () => setMessage({
                                                                                    error: true,
                                                                                    content: "Ocorreu um erro, tente novamente mais tarde."
                                                                                })
                                                                            )
                                                                            refresh();
                                                                        }
                                                                    }
                                                                    }
                                                                    variant="danger"
                                                                    className="text-white d-flex align-items-center"
                                                                >
                                                                    <MdDelete style={{ fontSize: "1.4em" }} />
                                                                </Button>
                                                                <Col xs={"auto"}>
                                      <Button
                                        disabled={categories.status === "1"}
                                        onClick={async () => {
                                          let active = window.confirm(
                                            "Esta ação ativará este cartão fidelidade."
                                          );
                                          if (active) {
                                            await activeProdutor(
                                              categories.id
                                            ).then(
                                              () => {
                                                setMessage({
                                                  error: false,
                                                  content:
                                                    "Cartão fidelidade ativado com sucesso.",
                                                });
                                                refresh();
                                              },
                                              () => {
                                                setMessage({
                                                  error: true,
                                                  content:
                                                    "Ocorreu um erro, tente novamente mais tarde.",
                                                });
                                              }
                                            );
                                          }
                                        }}
                                        size="sm"
                                        variant="primary"
                                        className="text-white d-flex align-items-center"
                                      >
                                        <MdCheckCircle
                                          style={{ fontSize: "1.4em" }}
                                        />{" "}
                                        Ativar
                                      </Button>
                                    </Col>
                                    <Col xs={"auto"}>
                                      <Button
                                        disabled={categories.status === "0"}
                                        onClick={async () => {
                                          let active = window.confirm(
                                            "Esta ação Desativara este cartão fidelidade."
                                          );
                                          if (active) {
                                            await desativar(
                                              categories.id
                                            ).then(
                                              () => {
                                                setMessage({
                                                  error: false,
                                                  content:
                                                    "Cartão fidelidade ativado com sucesso.",
                                                });
                                                refresh();
                                              },
                                              () => {
                                                setMessage({
                                                  error: true,
                                                  content:
                                                    "Ocorreu um erro, tente novamente mais tarde.",
                                                });
                                              }
                                            );
                                          }
                                        }}
                                        size="sm"
                                        variant="success"
                                        className="text-white d-flex align-items-center"
                                      >
                                        <MdCheckCircle
                                          style={{ fontSize: "1.4em" }}
                                        />{" "}
                                        Desativar
                                      </Button>
                                    </Col>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            {/* <div className="d-flex justify-content-end">
                        <Pagination>
                          <Pagination.First />
                          <Pagination.Prev />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Item>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item>
                          <Pagination.Next />
                          <Pagination.Last />
                        </Pagination>
                      </div> */}
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </RedirectContainer>
        </>
    );
}
