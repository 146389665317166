import handleChangeCep from "../../utils/validate_cep";
import {Col, Form, Row} from "react-bootstrap";

import InputMask from "react-input-mask";
import getState, { statesList } from "../../utils/uf";
import { useState, useEffect } from "react";
import {getCidades} from "../../services/api"

export default function Adresss({formik, tab}) {


  const [cities, setCities] = useState([]);

  useEffect(() => {
    async function getCities() {
      const uf = getState(formik.values.state);

      const cities =  await getCidades(uf);
      
      setCities(cities)
    }

    if(formik.values.state) {
      getCities()
    }

  },[formik.values.state])

    return (
        <>
        <h6 className="mb-3 text-primary">
            {tab && 
              <span className="text-secondary bg-primary p-1 rounded mr-1">
                {tab}
              </span>
            }
            Endereço
        </h6>
        <Form.Group>
            <Row className="align-items-center">
              
              <Col sm={12} md={4} xl={4}>
              <Form.Label>Estado *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  autoComplete="off"
                  
                  custom
                  isInvalid={ formik.errors.state && formik.touched.state }
                  defaultValue=""
                >
                  <option disabled value=""> Selecione </option>
                  {statesList.map((state) => (
                    <option key={state.id} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.state}
                </Form.Control.Feedback>
                </Col>
              <Col sm={12} md={4} xl={4}>
                <Form.Label>Cidade *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  autoComplete="off"
                 
                  custom
                  isInvalid={ formik.errors.city && formik.touched.city }
                  defaultValue=""
                >
                  <option disabled  value=""> Selecione </option>
                  {cities.map((c) => (
                    <option key={c.id} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </Form.Control>
                 <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        </>
    )
}

