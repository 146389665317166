import ReactDOM from 'react-dom';
import App from './App';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
ReactDOM.render(


    <GoogleOAuthProvider clientId="140747619313-sf646i7c0puk0edk26qp10ojo495ikkd.apps.googleusercontent.com">

        <App />
    </GoogleOAuthProvider>
    , document.getElementById('root'));