import Brand from "../../assets/img/logo-horizontal.png";
import { Image } from "react-bootstrap";
import "./styles.scss";

export default function Splash() {
  return (
    <div className="splash d-flex justify-content-center align-items-center bg-secondary">
      <Image src={Brand} fluid />
    </div>
  );
}
