import {
  Nav,
  NavDropdown,
  Container,
  Form,
  Button,
  Modal,
  Col,
  Row,
  ListGroup,
  Carousel,
  Image
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {

  searchPartners,
  getHighlightedProducts
} from "../../services/api";
import "./styles.scss";
import { GoChevronDown } from "react-icons/go";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import LoadingIndicator from "../../components/LoadingIndicator";
import Footer from "../../components/Footer";
import debounce from "lodash.debounce";
import useCategories from "../../hooks/useCategories";
import PaginationComponent from "../../components/Pagination";
import { Helmet } from "react-helmet";
import { textEllipsis } from "../../utils/mappers";
import qs from "qs";
import GiftVoucherCard from "../../components/GiftVoucherCard";
import qr from "../../assets/img/QR Code - Grupos de Ofertas.png"

export default function Giftvoucher({ location }) {
  const categories = useCategories();
  const [query, setQuery] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = useState();
  const [results, setResults] = useState(null);
  const [status, setStatus] = useState("loading");
  const [showModalLocation, setShowModalLocation] = useState(false);
  const [lastLocation, setLastLocation] = useState(null); // [city, uf
  const { idCategory } = useParams();
  const [lastCategory, setLastCategory] = useState(null);
  const [ascOrDesc, setAscOrDesc] = useState("DESC");
  const [highlights, setHighlights] = useState(null);
  const [loadingHighlights, setLoadingHighlights] = useState(true);

  const q = qs.parse(location.search, { ignoreQueryPrefix: true }).q;

  useEffect(() => {
    const p = sessionStorage.getItem("@app_mercadim:p");
    if (p) {
      setPage(Number(p));
    }
  }, []);

  useEffect(() => {
    getHighlightedProducts().then((data) => {
      setHighlights(data);
      setLoadingHighlights(false);
    });
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {

    if (q && query.length === 0) {
      const payload = {
        partner: q,
        ...(!showAll),
      };
      searchPartners(payload, page, ascOrDesc).then(
        (data) => {
          setResults(data);
          setStatus("result");
        }
      );
    } else {
      const dados = {
        partner: query,
        ...(!showAll),
      };

      searchPartners(dados, page, ascOrDesc).then((data) => {
        setResults(data);
        setStatus("result");
      });
    }

  }, [page, query, ascOrDesc, showAll, q]);

  function renderResults() {
    if (status !== "result") {
      return null;
    }

    if (results.data.length === 0) {
      return (
        <>
          <Container fluid>
            <Row>
              <Col className="my-5">
                <h5 className="text-muted text-center my-5">Sem resultados</h5>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    return (
      <>
        <Container>
          <Row>
            {results.data.map((giftvoucher) => (
              <Col key={giftvoucher.id} xs={12} sm={6} lg={4} xl={3}>
                <GiftVoucherCard giftvoucher={giftvoucher} />
              </Col>
            ))}
          </Row>
        </Container>
        <div className="my-4" >

          <PaginationComponent
            pagination={results}
            onClickPage={(page) => {
              setPage(page);
              if (query.length > 0) {
                const dados = {
                  partner: query,
                  ...(!showAll),
                };
                searchPartners(dados, page, ascOrDesc).then(
                  (data) => {
                    setResults(data);
                  }
                );
              }
            }}
          />
        </div>

      </>
    );
  }

  return (
    <>
      <Helmet title=": Parceiros" />

      <div className="marketplace-main-wrapper">




        <br></br>
        {!loadingHighlights && highlights && highlights.length > 0 && (
          <Container fluid>
            <div className="row">
              <Carousel className="center background carousel" controls={true} indicators={true}>
                {highlights.map((h) => (
                  <Carousel.Item key={h.id}>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "450px", margin: "20px 0" }}>
                      <a href={h.link} target="_blank" rel="noopener noreferrer">
                        <Image
                          src={h.image_url}
                          width='100%' // Tornar responsivo
                          style={{ objectFit: 'contain' }} // Evitar distorção da imagem
                          className="d-block center"
                        />
                      </a>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Container>

        )}

        <hr />
        <Container fluid>
          <Row className="justify-content-center"> {/* Centraliza o conteúdo na linha */}
            <Col md={6}> {/* Ajuste o tamanho da coluna para melhor se adaptar ao seu design */}
              <Form>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      name="q"
                      placeholder="O que está procurando?"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      title="Ordenar por preço"
                      onClick={() => {
                        if (ascOrDesc === "ASC") {
                          setAscOrDesc("DESC");
                        } else {
                          setAscOrDesc("ASC");
                        }
                        if (query.length > 0) {
                          const dados = {
                            partner: query,
                            ...(!showAll),
                          };
                          searchPartners(dados, page, ascOrDesc).then(
                            (data) => {
                              setResults(data);
                            }
                          );
                        }
                      }}
                    >
                      {ascOrDesc === "ASC" ? <BiSortUp /> : <BiSortDown />}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <div style={{ margin: "40px" }}></div>
          {renderResults()}
          {status === "loading" && <LoadingIndicator />}
        </Container>

      </div>



      <div id="app"> {/* ou 'root', dependendo do ID do elemento principal do seu aplicativo */}
        <div className="content">
          {/* Todo o conteúdo da página, exceto o rodapé, vai aqui */}
        </div>
        <Footer /> {/* Footer deve estar fora do .content mas dentro do #app */}
      </div>
    </>
  );
}





