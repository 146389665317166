import { Pagination } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";
import { getPageFromUrl } from "../../utils/formatters";
import {
  FaStepBackward,
  FaStepForward,
  FaFastBackward,
  FaFastForward,
} from "react-icons/fa";

export default function PaginationComponent({ pagination, onClickPage }) {
  const prevPage = getPageFromUrl(pagination.links[0].url);
  const nextPage = getPageFromUrl(
    pagination.links[pagination.links.length - 1].url
  );
  const firstPage = getPageFromUrl(pagination.first_page_url);
  const lastPage = getPageFromUrl(pagination.last_page_url);

  const { width } = useWindowSize();

  function buildPages() {
    return pagination.links.slice(1, -1).map((link, index) => {
      return (
        <Pagination.Item
          key={index}
          active={link.active}
          disabled={link.url === null}
          title={`Página ${link.label}`}
          onClick={() => onClickPage(link.label)}
        >
          {link.label}
        </Pagination.Item>
      );
    });
  }

  return (
    <Pagination size="sm">
      <Pagination.First
        title="Primeira página"
        disabled={firstPage === null}
        onClick={() => onClickPage(firstPage)}
      >
        <FaFastBackward />
      </Pagination.First>
      <Pagination.Prev
        title="Página anterior"
        disabled={prevPage === null}
        onClick={() => onClickPage(prevPage)}
      >
        <FaStepBackward />
      </Pagination.Prev>
      {width > 768 && buildPages()}
      <Pagination.Next
        title="Próxima página"
        disabled={nextPage === null}
        onClick={() => onClickPage(nextPage)}
      >
        <FaStepForward />
      </Pagination.Next>
      <Pagination.Last
        title="Última página"
        disabled={lastPage === null}
        onClick={() => onClickPage(lastPage)}
      >
        <FaFastForward />
      </Pagination.Last>
    </Pagination>
  );
}
