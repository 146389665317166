import LoadingIndicator from "../LoadingIndicator";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import {
  getGift,
  updateGiftVoucher,
  getAllCategories,
} from "../../services/api";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { UpdateGiftSchema } from "../../utils/validation_schemas";
import { Col, Form, Row, Button } from "react-bootstrap";
import useNotification from "../../contexts/notification";

export default function UpdateGiftVoucherForm({ giftId, onSubmit }) {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState({ status: "start" });
  const clearStatus = () => setStatus({ status: "start" });
  const [submitting, setSubmitting] = useState(false);
  const { setMessage } = useNotification();

  useEffect(() => {
    getAllCategories().then((data) => {
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    if(giftId !== null && giftId !== undefined) {
      getGift(giftId).then((data) => {
        formik.setValues({
          id: data.product.id,
          name: data.product.name,
          description: data.product.description,
          image: undefined,
          price: data.product.price,
          amount: data.product.amount,
          category_id: data.product.category_id,
        });
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftId]);

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      description: "",
      image: undefined,
      amount: "",
      category_id: [],
    },
    validationSchema: UpdateGiftSchema,
    onSubmit: async (values) => {
      if (values.image === undefined) {
        delete values.image;
      }
      setSubmitting(true);
      const updated = await updateGiftVoucher(values);
      /*setStatus(updated ? "success" : "error");*/
      setMessage({
        error: false,
        content: "Produto atualizado com sucesso",
      });
      setSubmitting(false);
      onSubmit?.();
      setStatus(updated ? "success" : "error");
    
    },
  });

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {/* <Container fluid> */}
        {/* <h6 className="mb-3 text-primary">Dados do Vale Presente</h6> */}
        {!isLoading && (
        <Form noValidate className="mt-3" onSubmit={formik.handleSubmit}>
          <Form.Group className="mt-1 mb-4" controlId="">
            <Row>
              <Col lg={6}>
                <Form.Label>Nome*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isValid={formik.touched.name && !formik.errors.name}
                  isInvalid={formik.errors.name}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Col>
              <Col lg={6}>
                <Form.Label>Preço*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Preço"
                  min="0"
                  name="price"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                  isValid={formik.touched.price && !formik.errors.price}
                  isInvalid={formik.errors.price}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.price}
                </Form.Control.Feedback>
              </Col>
              <Col lg={12}>
                <Form.Label>Descrição*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Descrição"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  isValid={
                    formik.touched.description && !formik.errors.description
                  }
                  isInvalid={formik.errors.description}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
              </Col>
              <Col lg={6}>
                <Form.Label>Imagem do vale presente*</Form.Label>
                <Form.File
                  id=""
                  accept="image/png, image/jpeg"
                  name="image"
                  lang="pt"
                  label={
                    formik.values.image
                      ? formik.values.image.name
                      : "Escolher arquivo"
                  }
                  onChange={(e) =>
                    formik.setFieldValue("image", e.currentTarget.files[0])
                  }
                  isInvalid={formik.errors.image}
                  feedback={formik.errors.image}
                  custom
                />
              </Col>
              <Col lg={6}>
                <Form.Label>Quantidade*</Form.Label>
                <Form.Control
                  className="my-1"
                  type="number"
                  name="amount"
                  min="0"
                  placeholder="Quantidade"
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  isValid={formik.touched.amount && !formik.errors.amount}
                  isInvalid={formik.errors.amount}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.amount}
                </Form.Control.Feedback>
              </Col>

              <Col xl={6} className="mt-1">
            <Form.Label>Categoria *</Form.Label>
            <Form.Control
              as="select"
              name="category_id"
              isValid={formik.touched.category_id && !formik.errors.category_id}
              isInvalid={formik.errors.category_id}
              onChange={formik.handleChange}
              value={formik.values.category_id}
              autoComplete="off"
              // disabled={formik.values.isPromotion === "1"}
              custom
            >
              <option>Selecione uma categoria</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Col>
            </Row>
            {status === "success" && (
              <SuccessAlert
                msg="Vale presemte atualizado"
                onClose={clearStatus}
              />
            )}

            {status === "error" && (
              <ErrorAlert msg="Um erro ocorreu." onClose={clearStatus} />
            )}
            <Button className="my-2" variant="primary" type="submit"
              disabled={submitting}>
              Atualizar
            </Button>
          </Form.Group>
        </Form>
        )}
      {/* </Container> */}
    </>
  );
}
