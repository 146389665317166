import useAuth from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import { myPurchases, getImage } from "../../services/api";
import LoadingIndicator from "../../components/LoadingIndicator";
import SidebarNavigation from "../../components/SidebarNavigation";
import { Helmet } from "react-helmet";
import { FaAngleDown } from "react-icons/fa";

import "./styles.scss";

export default function Purchases() {
  const { user } = useAuth();
  const [purchases, setPurchases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
    myPurchases().then((data) => {
      setPurchases(data);
      setIsLoading(false);
    });
  }, [user, history]);

  return (
    <>
      <Helmet title="Jersu Indica: Cartões Fidelidade" />
      <Container fluid>
        <div>
          <SidebarNavigation />
          <div className="content-panel">
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <>
                <h4 className="text-primary pt-3">Lista de Pedidos</h4>
                {purchases.length === 0 && (
                  <p className="text-center text-muted">
                    Nenhum pedido encontrado.
                  </p>
                )}

                {purchases.length > 0 && (
                  <Accordion>
                    {purchases.reverse().map((p, index) => (
                      <Card
                        key={p.id}
                        className="mb-2 card-purchase"
                        style={{ border: "1px solid rgba(0, 0, 0, 0.125)" }}
                      >
                        <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                          <Row className="align-items-center">
                            <Col>
                              {" "}
                              <Row className="row-cols-2 row-cols-md-4">
                                <Col>
                                  <p className="font-weight-bold">
                                    {" "}
                                    Número da Compra{" "}
                                  </p>
                                  <p># {p.id}</p>
                                </Col>
                                <Col>
                                  <p className="font-weight-bold"> Status </p>
                                  {p.status === "Confirmado" && (
                                    <p className="text-primary">{p.status}</p>
                                  )}
                                  {p.status !== "Confirmado" && (
                                    <p className="text-warning">{p.status}</p>
                                  )}
                                </Col>
                                <Col>
                                  <p className="font-weight-bold"> Preço </p>
                                  <p>R$ {p.total}</p>
                                </Col>
                                <Col>
                                  <p className="font-weight-bold"> Data </p>
                                  <p>
                                    {new Date(p.created_at).toLocaleDateString(
                                      "pt"
                                    )}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs="auto">
                              <FaAngleDown size="16" />
                            </Col>
                          </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index + 1}>
                          <Card.Body>
                            {/* Mais informações sobre a compra #{p.id} aqui. */}
                            <Row className="align-items-center">
                              <Col
                                className="d-flex justify-content-center align-items-center text-light"
                                xs={4}
                                sm={2}
                                style={{
                                  width: "96px",
                                  height: "96px",
                                }}
                              >
                                <img
                                  src={getImage(p.store[0].logo_store)}
                                  alt="Logo store"
                                  height="96px"
                                />
                              </Col>
                              <Col xs={8} sm={10}>
                                <p>
                                  Loja:{" "}
                                  <span className="text-primary">
                                    {p.name_establishment}
                                  </span>
                                </p>
                                <div>Telefone: {p.store[0].telephone}</div>
                                <div>E-mail: {p.store[0].email}</div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))}
                  </Accordion>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
