import { createContext, useContext } from "react";

export const CartContext = createContext([]);

export default function useCart() {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error("useCart must be used within an CartProvider.");
  }

  return context;
}
