import {
  Nav,
  NavDropdown,
  Carousel,
  Container,
  Form,
  Button,
  Modal,
  Col,
  Row,
  ListGroup,
  Image,
  NavItem,
  NavLink,
} from "react-bootstrap";
import React, { Link, useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import useLocationUser from "../../contexts/location";
import {
  getHighlightedProducts,
  searchProductInCityCategory,
} from "../../services/api";
import "./styles.scss";
import ProductCard from "../../components/ProductCard";
import Loader from "../../components/Loader/Loader";
import { GoChevronDown } from "react-icons/go";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import LoadingIndicator from "../../components/LoadingIndicator";
import Footer from "../../components/Footer";
import debounce from "lodash.debounce";

import PaginationComponent from "../../components/Pagination";
import { Helmet } from "react-helmet";
import { textEllipsis } from "../../utils/mappers";
import qs from "qs";
import qr from "../../assets/img/QR Code - Grupos de Ofertas.png"
export default function Marketplace({ match, location }) {
  const [categorySelected, setCategorySelected] = useState("Todas as categorias");
  const [query, setQuery] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [status, setStatus] = useState("loading");
  const history = useHistory();
  const { idCategory } = useParams();
  const { subCat } = useParams();
  const [lastCategory, setLastCategory] = useState(idCategory);
  const [selectedSubCategory, setSelectedSubCategory] = useState(subCat);

  const [ascOrDesc, setAscOrDesc] = useState("DESC");
  const [highlights, setHighlights] = useState(null);
  const [loadingHighlights, setLoadingHighlights] = useState(true);
  const q = qs.parse(location.search, { ignoreQueryPrefix: true }).q;


  useEffect(() => {
    const p = sessionStorage.getItem("@app_mercadim:p");
    if (p) {
      setPage(Number(p));
    }
  }, []);
  useEffect(() => {
    if (selectedSubCategory !== subCat) {
      setSelectedSubCategory(subCat);
      setPage(0); // Reinicia a paginação
      setResults([]); // Limpa os resultados existentes
      setStatus("loading");
    }
  }, [selectedSubCategory, subCat]);
  useEffect(() => {
    if (lastCategory !== idCategory) {
      setLastCategory(idCategory);
      setPage(0); // Reinicia a paginação
      setResults([]); // Limpa os resultados existentes
      setStatus("loading");
    }
  }, [lastCategory, idCategory]);
  useEffect(() => {
    sessionStorage.setItem("@app_mercadim:q", query);
    setPage(1); // Reinicia a paginação
    setResults([]); // Limpa os resultados existentes
    setStatus("loading");
  }, [query]);
  useEffect(() => {
    if (!q) {
      sessionStorage.setItem("@app_mercadim:p", page);

    }
  }, [page, q]);
  useEffect(() => {
    getHighlightedProducts().then((data) => {
      setHighlights(data);
      setLoadingHighlights(false);
    });
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {

    /*setTimeout(async () => {*/
    if (lastCategory) {

      const payload = {
        nameProduto: query,
        category_id: lastCategory,
        ...(!showAll),
      };

      searchProductInCityCategory(payload, page, ascOrDesc).then((data) => {
        setResults((prev) => {
          // Supondo que cada produto tenha um campo 'id' único
          const updatedData = data.data.filter(newItem => !prev.some(item => item.id === newItem.id));
          return [...prev, ...updatedData];
        });

        setStatus("result");
      });
    } else if (selectedSubCategory != null) {
      const payload = {
        category_type_id: selectedSubCategory,
        nameProduto: query,
        ...(!showAll),
      };

      searchProductInCityCategory(payload, page, ascOrDesc).then((data) => {
        setResults((prev) => {
          // Supondo que cada produto tenha um campo 'id' único
          const updatedData = data.data.filter(newItem => !prev.some(item => item.id === newItem.id));
          return [...prev, ...updatedData];
        });
        setStatus("result");
      });
    } else {
      setCategorySelected("Categorias");

      if (q && query.length === 0) {
        const payload = {
          nameProduto: q,
          category_id: lastCategory,
          ...(!showAll),
        };
        searchProductInCityCategory(payload, page, ascOrDesc).then((data) => {
          setResults((prev) => {
            // Supondo que cada produto tenha um campo 'id' único
            const updatedData = data.data.filter(newItem => !prev.some(item => item.id === newItem.id));
            return [...prev, ...updatedData];
          });
          setStatus("result");
        });
      } else {
        const dados = {
          nameProduto: query,
          category_id: lastCategory,
          ...(!showAll),
        };
        searchProductInCityCategory(dados, page, ascOrDesc).then((data) => {
          setResults((prev) => {
            // Supondo que cada produto tenha um campo 'id' único
            const updatedData = data.data.filter(newItem => !prev.some(item => item.id === newItem.id));
            return [...prev, ...updatedData];
          });
          setStatus("result");
        });
      }
    }
    /*  }, 1500);*/
  }, [lastCategory, selectedSubCategory, page, query, ascOrDesc, showAll, q]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = async () => {
    const nearBottom = window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight;
    if (nearBottom && status === "loading") {
      setStatus("result");  // Atualiza o estado para 'loading' antes de carregar mais dados
      setPage((prev) => prev + 1);
    }
  };
  function renderResults() {

    if (status !== "result") {
      return null;
    }

    if (results.length === 0) {
      return (
        <>
          <Container>
            <Row>
              <Col className="my-5">
                <h5 className="text-muted text-center my-5">Sem resultados</h5>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    return (
      <>
        <Container>
          <Row className="">
            {results.map((product, index) => (
              <Col key={index} xs={12} sm={6} lg={4} xl={3} style={{ marginBlockEnd: '25px' }}>
                <ProductCard product={product} />
              </Col>

            ))}

          </Row>
          <div style={{ margin: "80px" }}></div>
        </Container>
      </>
    );

  }
  return (
    <>
      <Helmet title="Jersu Indica: Marketplace" />
      <div className="marketplace-main-wrapper">
        <br></br>
        {!loadingHighlights && highlights && highlights.length > 0 && (
          <Container fluid>
            <div className="row">
              <Carousel className="center background carousel" controls={true} indicators={true}>
                {highlights.map((h) => (
                  <Carousel.Item key={h.id}>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "450px", margin: "20px 0" }}>
                      <a href={h.link} target="_blank" rel="noopener noreferrer">
                        <Image
                          src={h.image_url}
                          width='100%' // Tornar responsivo
                          style={{ objectFit: 'contain' }} // Evitar distorção da imagem
                          className="d-block center"
                        />
                      </a>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Container>

        )}

        <hr />
        <Container fluid>
          <Row className="justify-content-center"> {/* Centraliza o conteúdo na linha */}
            <Col md={6}> {/* Ajuste o tamanho da coluna para melhor se adaptar ao seu design */}
              <Form>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      name="q"
                      placeholder="O que está procurando?"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Col>
                  <Col xs="auto">

                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <div style={{ margin: "40px" }}></div>
          {renderResults()}
          {status === "loading" && <Loader />}
        </Container>
      </div>
      <Footer />
    </>
  );
}





