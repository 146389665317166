import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  getGift,
  getImage,
} from "../../../services/api";
import { currency } from "../../../utils/mappers";
import "./styles.scss";
import useCart from "../../../contexts/cart";
import { FiTruck } from "react-icons/fi";
import LoadingIndicator from "../../../components/LoadingIndicator";
import NotFound from "../../NotFound";
import Footer from "../../../components/Footer";
import useAuth from "../../../contexts/auth";

import { useHistory } from "react-router-dom";

export default function Voucher() {
  const { id } = useParams();
  const { user } = useAuth();
  const [giftvoucherData, setgiftvoucherData] = useState(null);
  // const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addToCart, productExists } = useCart();

  const history = useHistory();

  // const [showReviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    getGift(id).then(
      (data) => {
        setgiftvoucherData(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [id]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (giftvoucherData === null) {
    return <NotFound isComponent />;
  }

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col className="container__col" lg={6}>
            <Image
              className="container__image"
              src={getImage(giftvoucherData.product.image)}
              rounded
              fluid
            />
          </Col>
          <Col>
            <h2>{`${giftvoucherData.product.name} - ${giftvoucherData.entrepreneur[0].name_establishment}`}</h2>
            <h1 className="text-primary price">
              {currency(giftvoucherData.product.price)}
            </h1>
            <div className="my-3">
              <FiTruck size="24" />
              <h5 className="text-primary delivery ml-2">
                {giftvoucherData.entrepreneur[0].delivery_method[0].name}
              </h5>
            </div>
            <p className="my-3" style={{ whiteSpace: "pre-line" }}>
              {giftvoucherData.product.description}
            </p>
            <p className="text-primary ml-2">
              Quantidade:{giftvoucherData.product.amount}
            </p>
            <p>
              <span>Vendido por: </span>
              <Link
                to={`/loja/${giftvoucherData.entrepreneur[0].slug}`}
              >
                {giftvoucherData.entrepreneur[0].name_establishment}
              </Link>
            </p>
            <Row>
              <Col xs="auto">
                <Button
                  size="lg"
                  className="px-3 mt-1"
            
                  onClick={() => {
                    addToCart(giftvoucherData);
                    const entr = giftvoucherData["entrepreneur"][0];
                    if (user) {
                      history.push(
                        `/checkout?ent=${entr.id}`
                      );
                    } else {
                      history.push(
                        `/entrar?next=/checkout&ent=${entr.id}`
                      );
                    }
                  }}
                >
                  Comprar agora
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="px-3 mt-1"
                  onClick={() => addToCart(giftvoucherData)}
                  disabled={productExists(
                    giftvoucherData.entrepreneur[0].id,
                    giftvoucherData
                  )}
                >
                  {productExists(
                    giftvoucherData.entrepreneur[0].id,
                    giftvoucherData
                  )
                    ? `Adicionado`
                    : `Adicionar`}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div>
        <Footer />
      </div>
    </>
  );
}
