import { useFormik } from "formik";
import { useState } from "react";
import { SignupAdminSchema } from "../../utils/validation_schemas";
import { createSignupAdminFormData } from "../../utils/payloads";
import { Col, Form, Row, Button } from "react-bootstrap";

import InputMask from "react-input-mask";
import Adresss from '../Adresss';

export default function SignupAdminForm({ onSubmit }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
     
      telephone: "",
     
      city: "",
      state: "",
      email: "",
      password: "",
           
      show_password: false,
    },
    validationSchema: SignupAdminSchema,
    onSubmit: async (values) => {
      const admin = { ...values };
          onSubmit(createSignupAdminFormData(admin));
          setLoading(false);
    },
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do Administrador</h6>
      <Form.Group className="mt-1 mb-4" controlId="formClientData">
        <Row>
          <Col xl={8} md={7} sm={12}>
            <Form.Label>Nome *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
          
        
          <Col xl={4} md={4} sm={12}>
            <Form.Label>Telefone *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefone"
              name="telephone"
              as={InputMask}
              mask="(99)99999-9999"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={formik.errors.telephone && formik.touched.telephone}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.errors.email && formik.touched.email}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
          <Col sm={12} md={6} xl={6}>
            <Form.Label>Senha *</Form.Label>
            <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="password"
            placeholder="Nova senha"
            value={formik.values.password}
            onChange={formik.handleChange}
            isValid={formik.touched.password && !formik.errors.password}
            isInvalid={formik.errors.password}
          />
           <Form.Text className="text-muted">
            A senha deve ter no mínimo 8 caracteres entre números e letras.
          </Form.Text>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
          <Form.Check
            id="show-password-2"
            name="show_password"
            className="mb-2"
            type="switch"
            label="Mostrar senha"
            onChange={formik.handleChange}
            checked={formik.values.show_password}
            custom
          />
            </Col>
        </Row>
      </Form.Group>
        <Adresss formik={formik}/>
      <Form.Group/>
      <Button
        className="my-5"
        variant="primary"
        type="submit"
        disabled={loading}
        >
        Criar conta
      </Button>
      <p style={{ color: "red" }}>*Obrigatório</p>
    </Form>
  );
}
