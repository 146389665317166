import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getUser, updateUser } from "../../services/api";
import { defaultDateToISO, isoDateToDefault } from "../../utils/formatters";
import { UpdateClientSchema } from "../../utils/validation_schemas";
import LoadingIndicator from "../LoadingIndicator";
import { Col, Form, Row, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import useNotification from "../../contexts/notification";
import handleChangeCep from '../../utils/validate_cep'
import getState, { statesList,statesMap } from "../../utils/uf";
import {getCidades} from "../../services/api"
export default function UpdateClientForm({ clientId, onLoaded, onDone }) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ status: "start" });
  const clearStatus = () => setStatus({ status: "start" });
  const { setMessage } = useNotification();
  const [cities, setCities] = useState([]);
  const formik = useFormik({
    initialValues: {
      name: "",
    
      telephone: "",
      email: "",
     
      password: "",
      city: "",
      state: "",
      show_password: false,
    },
    validationSchema: UpdateClientSchema,
    onSubmit: async (values) => {
      const client = {
        ...values,
       
        type: "client",
      };
    
      const updated = await updateUser(client);
      onDone?.();

      setStatus(updated ? { status: "success" } : { status: "error" });
    },
  });
  useEffect(() => {
    async function getCities() {
      
      const uf = getState(formik.values.state);

      const cities =  await getCidades(uf);
      
      setCities(cities)
    }

    if(formik.values.state) {
      getCities()
    }

  },[formik.values.state])
  useEffect(() => {
    
    getUser(clientId).then(
      (data) => {
        const [client] = data;
        formik.setValues({
          id: client.id,
          name: client.name,
        
        
          telephone: client.telephone,
          email: client.email,
          city: client.city ? client.city.name : '',
          state: client.city ? statesMap[client.city.uf] : '',
          password: "",
          conf_password: "",
          show_password: false,
        });

        setLoading(false);
        if (onLoaded) {
          onLoaded();
        }
      },
      () =>
        setMessage({
          error: true,
          content: "Ocorreu um erro. Tente novamente mais tarde",
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, setMessage]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="pt-2">
      <h6 className="mb-3 text-primary">Meus Dados</h6>
      <Form.Group as={Row}>
        <Col lg={4}>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Seu nome"
            value={formik.values.name}
            onChange={formik.handleChange}
            isValid={formik.touched.name && !formik.errors.name}
            isInvalid={formik.errors.name}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.name}
          </Form.Control.Feedback>
        </Col>
       
        
        <Col lg={4}>
        <Form.Label>Telefone *</Form.Label>
          <Form.Control
            type="text"
            name="telephone"
            placeholder="Telefone"
            as={InputMask}
            mask="(99)99999-9999"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            isValid={formik.touched.telephone && !formik.errors.telephone}
            isInvalid={formik.errors.telephone}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.telephone}
          </Form.Control.Feedback>
        </Col>
        <Col lg={8}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Seu email"
            value={formik.values.email}
            onChange={formik.handleChange}
            isValid={formik.touched.email && !formik.errors.email}
            isInvalid={formik.errors.email}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <h6 className="mt-3 mb-3 text-primary">Endereço</h6>
      <Form.Group>
            <Row className="align-items-center">
              
              <Col sm={12} md={4} xl={4}>
              <Form.Label>Estado *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  autoComplete="off"
                  
                  custom
                  isInvalid={ formik.errors.state && formik.touched.state }
                  defaultValue=""
                >
                  <option disabled value=""> Selecione </option>
                  {statesList.map((state) => (
                    <option key={state.id} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.state}
                </Form.Control.Feedback>
                </Col>
              <Col sm={12} md={4} xl={4}>
                <Form.Label>Cidade *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  autoComplete="off"
                 
                  custom
                  isInvalid={ formik.errors.city && formik.touched.city }
                  defaultValue=""
                >
                  <option disabled  value=""> Selecione </option>
                  {cities.map((c) => (
                    <option key={c.id} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </Form.Control>
                 <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
      <h6 className="mb-3 text-primary">Senha</h6>
      <Form.Group as={Row}>
        <Col lg={6}>
          <Form.Label>Nova senha</Form.Label>
          <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="password"
            placeholder="Nova senha"
            value={formik.values.password}
            onChange={formik.handleChange}
            isValid={formik.touched.password && !formik.errors.password}
            isInvalid={formik.errors.password}
          />
          <Form.Text className="text-muted">
            A senha deve ter no mínimo 8 caracteres entre números e letras.
          </Form.Text>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
          <Form.Check
            id="show-password-2"
            name="show_password"
            className="mb-2"
            type="switch"
            label="Mostrar senha"
            onChange={formik.handleChange}
            checked={formik.values.show_password}
            custom
          />
        </Col>

        {/*
        // TODO : Confirmação de Senha
        <Col lg={6}>
          <Form.Label>Confirmar senha</Form.Label>
          <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="conf_password"
            placeholder="Confirmar senha"
            value={formik.values.conf_password}
            onChange={formik.handleChange}
          />
        </Col> */}
      </Form.Group>
      {status.status === "success" && (
        <SuccessAlert msg="Perfil atualizado com sucesso." onClose={clearStatus} />
      )}
      {status.status === "error" && (
        <ErrorAlert msg="Ocorreu um erro, por favor tente novamente mais tarde." onClose={clearStatus} />
      )}
      <Form.Group as={Row}>
        <Col xs="auto">
          <Button variant="primary" type="submit">
            Atualizar
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
}
