import { Col, Container, Row, Form, Card } from "react-bootstrap";
import RedirectContainer from "../../../components/RedirectContainer";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
// import Grafico from "../../../components/Grafico";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import {
  getDashboardEntrepreneur,
  getDashboardEntrepreneurFilterByMonthYear,
} from "../../../services/api";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { currency } from "../../../utils/mappers";
import useNotification from "../../../contexts/notification";
import GraficoEmpreendedor from '../../../components/GraficoEmpreendedor';

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [monthYear, setMonthYear] = useState("");
  const [dashboard, setDashboard] = useState(null);
  const { setMessage } = useNotification();

  useEffect(() => {
    getDashboardEntrepreneur().then(
      (data) => {
        setDashboard(data);
        setLoading(false);
      },
      () =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
    );
  }, [setMessage]);

  useEffect(() => {
    if (monthYear.length !== 0) {
      const [year, month] = monthYear.split("-");
      getDashboardEntrepreneurFilterByMonthYear(month, year).then(
        setDashboard,
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    }
  }, [monthYear, setMessage]);

  return (
    <RedirectContainer role="poster">
      <Helmet title="Jersu Indica: Dashboard Empreendedor" />
      <Container fluid>
        <div>
          <SidebarPanelNavigation isAdmin={false} />
          <div className="content-panel">
            {loading && <LoadingIndicator />}
            {!loading && (
              <>
                <Row>
                  <Col lg={4} className="mt-3">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Form.Control
                        type="month"
                        placeholder="Período"
                        value={monthYear}
                        onChange={(e) => setMonthYear(e.target.value)}
                      />
                    </Form>
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col lg={4} className="my-1">
                    <Card>
                      <Card.Body>
                        <h1 className="text-primary text-center display-4">
                          {currency(dashboard.grossBiling)}
                        </h1>
                        <span className="text-primary d-block text-center">
                          Faturamento bruto
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={4} className="my-1">
                    <Card>
                      <Card.Body>
                        <h1 className="text-primary text-center display-4">
                          {dashboard.unitsSold}
                        </h1>
                        <span className="text-primary d-block text-center">
                          Unidades vendidas
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={4} className="my-1">
                    <Card>
                      <Card.Body>
                        <h1 className="text-primary text-center display-4">
                          {dashboard.totalSalesAmount}
                        </h1>
                        <span className="text-primary d-block text-center">
                          Quantidade de Cliques
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={4} className="my-1">
                    <Card>
                      <Card.Body>
                        <h1 className="text-primary text-center display-4">
                          {dashboard.totalClients}
                        </h1>
                        <span className="text-primary d-block text-center">
                          Total de Clientes
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col lg={12}>
                    <GraficoEmpreendedor />
                  </Col>
                </Row>
              </>
            )}
          </div>
          )
        </div>
      </Container>
    </RedirectContainer>
  );
}
