import { number } from "yup";
import {
  clearObject,
  defaultDateToISO,
  unformat,
} from "./formatters";

function formData(payload) {
  const data = new FormData();
  Object.keys(payload).forEach((key) => data.append(key, payload[key]));
  return data;
}

export function createSignupClientFormData(client) {
  
  const payload = { ...client };
  return formData(payload);
}

export function createSignupAdminFormData(admin) {
  

  const payload = { ...admin };

  return formData(payload);
}

export function createSignupEntrepreneurFormData(entrepreneur) {
 
  const payload = { ...entrepreneur};

  return formData(payload);
}

export function createLoginFormData(loginData) {
  const identifier = loginData.email;
  const payload = { ...loginData, email: identifier };

  return formData(payload);
}

export function createUpdateEntrepreneurFormData(updateData) {

  const payload = { ...updateData };

  return formData(payload);
}

export function createProductFormData(productData) {
  
  
  const payload = {
    ...productData,
    category_type_id: Number(productData.category_type_id),
    price_on_offer: Number(productData.price_on_offer),
    have_cupom: Number(productData.have_cupom),
  };

  return formData(payload);
}

export function UpdateProductData(productData){
  let previous_price = productData.previous_price; 
  if(productData.previous_price == null){
    previous_price = "";
  }
  
  const payload = {
    ...productData,
    previous_price:previous_price,
    category_type_id: Number(productData.category_type_id),
    send_notifications: productData.send_notifications,
    price_on_offer: Number(productData.price_on_offer),
    have_cupom: Number(productData.have_cupom),
  };

  

  return payload;
}

export function createGiftVoucherFormData(giftVoucherData) {

  const payload = {
    ...giftVoucherData,
    name: String(giftVoucherData.name),
    price: Number(giftVoucherData.price),
    description: String(giftVoucherData.description),

    amount: Number(giftVoucherData.amount),
    category_id: Number(giftVoucherData.category_id),
  };
  return formData(payload);
}
