import { useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Image, Form, ListGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProductCard from "../../../components/ProductCard";
import { getImage, getStore, getStoreCategories } from "../../../services/api";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Footer from "../../../components/Footer";
import PaginationComponent from "../../../components/Pagination";
import "./styles.scss";
import NotFound from "../../NotFound";

export default function Store() {
  const { id } = useParams();
  const [store, setStore] = useState(null);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [status, setStatus] = useState("loading");
  const [loadingHighlights, setLoadingHighlights] = useState(true);

  useEffect(() => {
    getStoreCategories(id).then((data) => {
      setCategories(data);
      setLoadingHighlights(false);
     
    });
  }, []);
  useEffect(() => {
    setStatus("loading");
    const dados = {
      nameProduto: query,
    };

    if (selectedCategory !== "") {
      dados.category = selectedCategory;
    }
   
    getStore(id, dados, page).then((data) => {
      setStore(data);
      setResults(data.products);
      setLoading(false);
      setStatus("result");
    });

  }, [id, page, query, selectedCategory]);

  function renderResults() {
    if (status !== "result") {
      return null;
    }

    if (results.data.length === 0) {
      return (
        <>
          <Container>
            <Row>
              <Col className="my-5">
                <h5 className="text-muted text-center my-5">Sem resultados</h5>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    return (
      <>
        <Container>
          <Row>
            {results.data.map((product) => (
              <Col key={product.id} xs={12} sm={6} lg={4} xl={3}>
                <ProductCard product={product} />
              </Col>
            ))}
          </Row>
        </Container>
        <div className="my-4">
          <PaginationComponent
            pagination={results}
            onClickPage={(page) => {
              setPage(page);
              if (query.length > 0) {
                const dados = {
                  nameProduto: query,
                };
                getStore(id, dados, page).then((data) => {
                  setResults(data.products);
                });
              }
            }}
          />
        </div>
      </>
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  if (store === undefined) {
    return <NotFound isComponent />;
  }

  return (
    <>
      <Container className="py-5">
        <Row className="row-cols-1, row-cols-sm-2">
          <Col xs={12} sm={6} md={4}>
            <Image
              src={store.profile[0].image_url}
              className="store-logo"
              rounded
              // fluid
              style={{  maxHeight: "300px", objectFit: "cover" }}
            />
          </Col>
          <Col className="py-1" sm={6} md={8}>
            <h2 className="text-primary ">
              {store.profile[0].name}
            </h2>
            <div>
              {/* <h5 className="d-inline text-muted">{`${store.sales} vendas`}</h5> */}
              <h5 className="mb-4 font-weight-normal description">
                {store.profile[0].description}
              </h5>
              {
                <h5 className="font-weight-normal">{`${results.total} produtos`}</h5>
              }
              <h5 className="my-3 font-weight-normal">
                {store.profile[0].telephone}
              </h5>
              <h5 className="my-3 font-weight-normal">
                {store.profile[0].email}
              </h5>
            </div>
          </Col>
        </Row>
        <hr />
        {/* <Container> */}
        <Container fluid>
          <Form>
            <h5 className="text-primary my-3">
              Busque seus produtos favoritos
            </h5>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="q"
                  placeholder="O que está procurando?"
                  // onKeyUp={handleQuery}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Col>
            </Row>
          </Form>

          <hr />

          <Row>
          {!loadingHighlights  && (
            <Col xs='auto'>
              <h5 className="text-primary my-3">Categorias</h5>

              <ListGroup>
                <ListGroup.Item
                  action
                  onClick={() => setSelectedCategory("")}
                  style={{
                    backgroundColor: selectedCategory === "" ? "#29a578" : "white",
                    color: selectedCategory === "" ? "white" : "black",
                  }}
                >
                  Todas
                </ListGroup.Item>
                
                {categories.map((category) => (
                  
                  <ListGroup.Item
                    key={category.id}
                    action
                    onClick={() => setSelectedCategory(category.id)}
                    style={{
                      backgroundColor:
                        category.id === selectedCategory ? "#29a578" : "white",
                      color: category.id === selectedCategory ? "white" : "black",
                    }}
                  >
                    {`${category.name} (${category.total_products_count})`}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            )}
            <Col>
              {renderResults()}
              {status === "loading" && <LoadingIndicator />}
            </Col>
            
          </Row>

        </Container>
      
      </Container>
      <Footer />
    </>
  );
}
